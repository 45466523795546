import * as React from "react";
import Box from "@mui/material/Box";

import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";

import Typography from "@mui/material/Typography";

import {
  Avatar,
  Button,
  DialogContentText,
  Divider,
  RadioGroup,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Menu,
  MenuItem,
  Tooltip,
} from "@mui/material";
import { FormDialog } from "../dialog";
import { ListButtonRadio } from "../radiobutton/radiobutton";
import { ListButton } from "../button/button.jsx";
import { Container } from "@mui/system";
import { useLocation, useNavigate } from "react-router-dom";
import {
  pagename,
  removeToken,
  getSelectedProject,
  getSelectedProjectString,
} from "utils/helper";
import useStyles from "./navbar.styles";
import {
  fetchAll,
  fetchIssue,
  fetchScope,
  fetchdash,
  fetchquality,
  fetchrole,
  fetchscraper,
  fetchsource,
  fetchuser,
  getReports,
  getScraperName,
  issueProjectName,
  qualityProjectName,
  saveFilters,
  setprojectSla,
} from "redux/app/actions";
import { useDispatch, useSelector } from "react-redux";
import ChangePassword from "components/pages/auth/changePassword/changePassword";
import MaterialUIModal from "../modal/modal";
import { useEffect } from "react";
import { logoutUser } from "redux/usermanagement/action";
import {
  ChevronRightRounded as ChevronRightIcon,
  CheckCircleOutlineRounded as CheckIcon,
  HighlightOffRounded as CloseIcon,
} from "@mui/icons-material";
import axios from "axios";
import { useState } from "react";

const drawerWidth = 120;
const profileMenu = [];

export default function Navbar() {
  const classes = useStyles();
  const navigate = useNavigate();
  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const [anchorElUser, setAnchorElUser] = React.useState(null);
  const [show, setShow] = React.useState(1);
  const location = useLocation();
  const [id, setId] = React.useState(0);
  const user = useSelector((state) => state.userManagement);
  const name = localStorage.getItem("firstName");
  const last = localStorage.getItem("lastName");
  const role = localStorage.getItem("role");
  const [issue, setIssue] = useState(""); // State for handling issues
  const [scope, setScope] = useState(""); // State for managing scope
  const [source, setSource] = useState(""); // State for data source
  const [quality, setQuality] = useState(""); // State for data quality
  const [scraper, setScraper] = useState(""); // State for scraper information
  const [reports, setReports] = useState("");
  const [dashboard, setDashboard] = useState(""); // State for dashboard settings
  const [sla, setSla] = useState("");
  const [userManagement, setUserManagement] = useState(""); // State for user management
  const [roleManagement, setRoleManagement] = useState("");
  useEffect(() => {
    getprojectName();
  }, [name, last, role, location.pathname, user.projectDetails]);
  const project = useSelector((state) => state.project);
  const pageName = pagename(location.pathname);
  const [open, setOpen] = React.useState(false);
  const dispatch = useDispatch();
  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };
  const handleCloseUserMenuLogout = () => {
    //  dispatch(logoutUser()).then((res)=>{
    setAnchorElUser(null);
    localStorage.removeItem("role");
    localStorage.removeItem("firstName");
    localStorage.removeItem("lastName");
    localStorage.removeItem("roleId");
    localStorage.removeItem("email");
    localStorage.removeItem("roleData");
    localStorage.removeItem("token");
    localStorage.removeItem("refreshToken");
    removeToken();
    // const redirectUrl = encodeURIComponent("http://localhost:3000");
    const redirectUrl = encodeURIComponent(
      "https://jatodashboarduatui.azurewebsites.net"
    );

    window.location.href = `https://jatodashboardprodapi.azurewebsites.net/api/v1/users/signout`;
  };
  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  // States and Methods for Projects Modal
  const [showProjects, setShowProjects] = React.useState(true);
  const handleCloseProjectsModal = (event, reason) => {
    setShow(show + 1);

    if (reason === "backdropClick") return true;
    setShowProjects(false);
    if (show > 1) {
      setShowApplyChanges(true);
    } else {
      // axios.defaults.headers.common["X-Project-ID"] = id;
      dispatch(fetchAll(id));
      dispatch(fetchIssue(id));
      dispatch(fetchScope(id));
      dispatch(fetchscraper(id));
      dispatch(fetchsource(id));
      dispatch(fetchdash(id));
      dispatch(fetchuser(id));
      dispatch(fetchrole(id));
      dispatch(getReports(id));
      dispatch(fetchquality(id));
      dispatch(setprojectSla(id));
      dispatch(qualityProjectName(projectString));
      dispatch(issueProjectName(projectString));
      dispatch(getScraperName(projectString));
      setIssue(projectString);
      setSla(projectString);
      setReports(projectString);
      setScope(projectString);
      setSource(projectString);
      setQuality(projectString);
      setScraper(projectString);
      setDashboard(projectString);
      setUserManagement(projectString);
      setRoleManagement(projectString);
      navigate("/manage-issue-/-request");
    }
  };
  const applyallyes = () => {
    dispatch(fetchAll(id));
    dispatch(fetchIssue(id));
    dispatch(setprojectSla(id));
    dispatch(fetchScope(id));
    dispatch(fetchscraper(id));
    dispatch(fetchsource(id));
    dispatch(fetchdash(id));
    dispatch(fetchuser(id));
    dispatch(fetchrole(id));
    dispatch(getReports(id));
    setReports(projectString);
    

    dispatch(fetchquality(id));
    setShowApplyChanges(false);
    dispatch(issueProjectName(projectString));
    dispatch(qualityProjectName(projectString));
    dispatch(getScraperName(projectString));

    setIssue(projectString);
    setScope(projectString);
    setSource(projectString);
    setQuality(projectString);
    setScraper(projectString);
    setDashboard(projectString);
    setSla(projectString);
    setUserManagement(projectString);
    setRoleManagement(projectString);
  };
  const applyallno = () => {
    if (location.pathname === "/manage-issue-/-request") {
      dispatch(fetchIssue(id));
      dispatch(issueProjectName(projectString));

      setIssue(projectString);
    }
    if (location.pathname === "/sla-and-holidays") {
      dispatch(setprojectSla(id));

      setSla(projectString);
    } else if (location.pathname === "/manage-quality") {
      dispatch(fetchquality(id));
      dispatch(qualityProjectName(projectString));

      setQuality(projectString);
    } else if (location.pathname === "/manage-model-scope") {
      dispatch(fetchScope(id));
      setScope(projectString);
    } else if (location.pathname === "/manage-scraper") {
      dispatch(fetchscraper(id));
      dispatch(getScraperName(projectString));

      setScraper(projectString);
    } else if (location.pathname === "/manage-sources") {
      dispatch(fetchsource(id));
      setSource(projectString);
    } else if (location.pathname === "/role-management") {
      dispatch(fetchrole(id));
      setRoleManagement(projectString);
    } else if (location.pathname === "/user-management") {
      dispatch(fetchuser(id));
      setUserManagement(projectString);
    } else if (location.pathname === "/reports") {
      dispatch(getReports(id));
      setReports(projectString);
    } else if (
      location.pathname === "/dashboard-and-reports" ||
      location.pathname === "/dashboard-and-reports/country-wise-status" ||
      location.pathname === "/dashboard-and-reports/region-wise-status" ||
      location.pathname ===
        "/dashboard-and-reports/source-URLs-with-price-point-changes" ||
      location.pathname ===
        "/dashboard-and-reports/source-URLs-with-no-records" ||
      location.pathname ===
        "/dashboard-and-reports/delivered-file-and-data-count"
    ) {
      dispatch(fetchdash(id));
      setDashboard(projectString);
    }

    setShowApplyChanges(false);
  };

  const getprojectName = () => {
    if (location.pathname === "/manage-issue-/-request") {
      dispatch(issueProjectName(issue));
      setId(project.manageissue);
      return setProjectString(issue);
    }
    if (location.pathname === "/sla-and-holidays") {
      setId(project.sla);
      return setProjectString(sla);
    } else if (location.pathname === "/manage-quality") {
      setId(project.quality);
      dispatch(qualityProjectName(quality));

      return setProjectString(quality);
    } else if (location.pathname === "/manage-model-scope") {
      setId(project.managescope);
      return setProjectString(scope);
    } else if (location.pathname === "/manage-scraper") {
      dispatch(getScraperName(scraper));

      setId(project.managescraper);
      return setProjectString(scraper);
    } else if (location.pathname === "/reports") {
      setId(project.reports);

      setProjectString(reports);
    } else if (location.pathname === "/manage-sources") {
      setId(project.managesource);
      return setProjectString(source);
    } else if (location.pathname === "/role-management") {
      setId(project.role);
      return setProjectString(roleManagement);
    } else if (location.pathname === "/user-management") {
      setId(project.user);
      return setProjectString(userManagement);
    } else if (
      location.pathname === "/dashboard-and-reports" ||
      location.pathname === "/dashboard-and-reports/country-wise-status" ||
      location.pathname === "/dashboard-and-reports/region-wise-status" ||
      location.pathname ===
        "/dashboard-and-reports/source-URLs-with-price-point-changes" ||
      location.pathname ===
        "/dashboard-and-reports/source-URLs-with-no-records" ||
      location.pathname ===
        "/dashboard-and-reports/delivered-file-and-data-count"
    ) {
      setId(project.dashboard);
      return setProjectString(dashboard);
    }
  };
  // States and Methods for Apply Changes Modal
  const [showApplyChanges, setShowApplyChanges] = React.useState(false);
  const handleCloseApplyChangesModal = (event) => {
    setShowApplyChanges(false);
  };

  const [projectChecked, setProjectChecked] = React.useState(0);
  // const selectedProject = getSelectedProject(user.projectDetails, projectChecked);
  const [projectString, setProjectString] = React.useState("");
  const handleProjectToggle = (value) => () => {
    setProjectChecked(value.projectId);
    setId(value.projectId);
    setProjectString(getSelectedProjectString(value));
  };

  return (
    <>
      {location.pathname !== "/" &&
        location.pathname !== "/reset-password" &&
        location.pathname !== "/change-password" &&
        location.pathname !== "/error" &&
        location.pathname !== "/forgot-password" && (
          <div
            style={{
              visibility:
                location.pathname !== "/projectselection"
                  ? "visible"
                  : "hidden",
            }}
          >
            <AppBar
              position="static"
              elevation={0}
              //  sx={{ width: `calc(100% - ${drawerWidth}px)`, ml: `${drawerWidth}px` }}
              sx={{ width: "calc(100%)" }}
            >
              <Container
                component="nav"
                sx={{ maxWidth: "calc(100%) !important" }}
                // maxWidth="xl"
                // sx={{ maxWidth: "calc(100vw)"}}
              >
                <Toolbar disableGutters>
                  <Typography variant="h5" sx={{ flexGrow: 1 }} noWrap>
                    {pageName === "Reports" ? "Weekly BAU Status" : pageName}
                  </Typography>

                  <Box sx={{ mx: { xs: 1, md: 2 } }}>
                    <ListButton
                      message="Switch Project"
                      secondary={projectString}
                      onClick={() => setShowProjects(true)}
                    />
                  </Box>
                  <Box sx={{ flexGrow: 0 }}>
                    <IconButton
                      onClick={handleOpenUserMenu}
                      sx={{ p: 0 }}
                      disableRipple
                    >
                      <Avatar
                        alt={user.firstName}
                        src="/static/images/avatar/2.jpg"
                        sx={{ mr: 1, p: 0 }}
                      />
                      <List>
                        <ListItem sx={{ m: 0, p: 0 }}>
                          <ListItemText
                            primary={
                              <>
                                <Typography
                                  variant="subtitle1"
                                  className={classes.userName}
                                >
                                  {name + " " + last}
                                </Typography>
                              </>
                            }
                            secondary={
                              <>
                                <Typography variant="caption">
                                  {role}
                                </Typography>
                              </>
                            }
                          />
                        </ListItem>
                      </List>
                    </IconButton>
                    <Menu
                      elevation={2}
                      sx={{ mt: "45px", fontSize: 12 }}
                      id="menu-appbar"
                      anchorEl={anchorElUser}
                      anchorOrigin={{
                        vertical: "top",
                        horizontal: "right",
                      }}
                      keepMounted
                      transformOrigin={{
                        vertical: "top",
                        horizontal: "right",
                      }}
                      open={Boolean(anchorElUser)}
                      onClose={handleCloseUserMenu}
                    >
                      {profileMenu.map((setting) => (
                        <MenuItem
                          key={setting}
                          onClick={() => {
                            navigate("/change-password");
                            handleCloseUserMenu();
                          }}
                        >
                          <Typography textAlign="center" variant="caption">
                            {setting}
                          </Typography>
                        </MenuItem>
                      ))}

                      <Divider />
                      <MenuItem
                        onClick={
                          "Logout"
                            ? handleCloseUserMenuLogout
                            : handleCloseUserMenu
                        }
                      >
                        <Typography textAlign="center" variant="caption">
                          Logout
                        </Typography>
                      </MenuItem>
                    </Menu>
                  </Box>
                </Toolbar>
              </Container>
            </AppBar>
            {user?.projectDetails?.length > 0 ? (
              <FormDialog
                id="switch-projects-modal"
                open={
                  user?.projectDetails?.length === 1 && show > 1
                    ? false
                    : showProjects
                }
                // onClose={handleCloseProjectsModal}
                title="Select Project"
                // titleHelper="Pick project you’d like to see in your dashboard"
                action={
                  <>
                    <Button
                      disabled={!projectString}
                      endIcon={<ChevronRightIcon />}
                      onClick={
                        user?.projectDetails?.length === 0
                          ? ""
                          : handleCloseProjectsModal
                      }
                    >
                      Continue
                    </Button>
                  </>
                }
              >
                <List>
                  {user.projectDetails.map((project, index) => {
                    return (
                      <ListButtonRadio
                        key={`project_${project.projectId}`}
                        isListItem={true}
                        name="selected-project"
                        label={project.projectName}
                        onChange={handleProjectToggle(project)}
                        checked={id === project.projectId}
                      />
                    );
                  })}
                </List>
              </FormDialog>
            ) : (
              <></>
            )}
            <FormDialog
              id="confirmation-modal"
              open={showApplyChanges}
              // onClose={handleCloseApplyChangesModal}
              title="Apply Changes"
              action={
                <>
                  <Button
                    startIcon={<CloseIcon />}
                    onClick={() => applyallno()}
                  >
                    No
                  </Button>
                  <Button
                    variant="contained"
                    startIcon={<CheckIcon />}
                    onClick={() => applyallyes()}
                  >
                    Yes
                  </Button>
                </>
              }
            >
              <DialogContentText align="center" variant="subtitle1">
                Apply changes to all modules?
              </DialogContentText>
            </FormDialog>
          </div>
        )}
    </>
  );
}
