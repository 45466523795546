var baseApiUrl = process.env.REACT_APP_MANAGE_ISSUE_BASE_URL;
const baseurl = baseApiUrl + "api";
const DROPDOWN_BASIC_URL = baseurl + "/listdropdown";
export const COUNTRY_DROPDOW = DROPDOWN_BASIC_URL + "/getallcountries";
export const MAKE_DROPDOW = DROPDOWN_BASIC_URL + "/getallmake";
export const MODEL_DROPDOW = DROPDOWN_BASIC_URL + "/getallmodel";
export const MAKE_STRING_DROPDOW =
  DROPDOWN_BASIC_URL + "/makeoncountryviewmodeladvancefilter";
export const COMPLICITYMANAGESOURCE =
  DROPDOWN_BASIC_URL + "/getallissuecomplicityformanagesource";
export const STATUS_ON_CATEGORY =
  DROPDOWN_BASIC_URL + "/getallresolutionstatusonissuecategory";
export const CATEGORY_DROPDOW = DROPDOWN_BASIC_URL + "/getallissuecategory";
export const TYPE_DROPDOW = DROPDOWN_BASIC_URL + "/getallissuetype";
export const PRIORITY_DROPDOW = DROPDOWN_BASIC_URL + "/getallissuepriority";
export const FIELD_DROPDOW = DROPDOWN_BASIC_URL + "/getallfields";
export const RERUN_DROPDOW = DROPDOWN_BASIC_URL + "/getallrerun";
export const WORK_TYPE_DROPDOW = DROPDOWN_BASIC_URL + "/getallworktype";
export const STATUS_DROPDOW = DROPDOWN_BASIC_URL + "/getallstatus";
export const REPORTEDBY_DROPDOW = DROPDOWN_BASIC_URL + "/getallreportedby";
export const REASON = DROPDOWN_BASIC_URL + "/getallreasonforfailure";
export const PROJECT = DROPDOWN_BASIC_URL + "/getallproject";
export const WEBSITE = DROPDOWN_BASIC_URL + "/getallwebsite";
export const DEVELOPER_COMPLEXITY_DROPDOWN =
  DROPDOWN_BASIC_URL + "/getallissuecomplicity";
export const OPEN_DROPDOW = DROPDOWN_BASIC_URL + "/getopennoissuestatus";
export const TICKET_ID = DROPDOWN_BASIC_URL + "/getlastticketid";
export const TICKET_ID_LIST = DROPDOWN_BASIC_URL + "/getallticketid";
export const COMPLEXITY_DROPDOWN =
  DROPDOWN_BASIC_URL + "/getallissuecomplicity";
export const DROPDOWN_REASON_FOR_FAILURE_SCRAPER =
  DROPDOWN_BASIC_URL + "/getallreasonforfailuremanagescrapper";
export const DROPDOWN_PROVIDER = DROPDOWN_BASIC_URL + "/getallleasingprovider";
export const RESOLUTION_STATUS = DROPDOWN_BASIC_URL + "/getallresolutionstatus";
export const USERS = DROPDOWN_BASIC_URL + "/getalluserdropdown";
const baseurlformanageissue = baseurl + "/v1/manageissues";
export const BASE_URL_ISSUE = baseurlformanageissue;
export const MANAGE_ISSUE_URL = baseurlformanageissue + "/getallissues";
export const MANAGE_ISSUE_EDIT_URL = baseurlformanageissue + "/";
export const MANAGE_ISSUE_DELETE_URL = baseurlformanageissue + "/";
export const MANAGE_ISSUE_UPDATE_URL = baseurlformanageissue + "/editissue";
export const MANAGE_ISSUE_DEV_COMMENTS =
  baseurlformanageissue + "/createnewdevelopercomments";
export const MANAGE_ISSUE_EXPORT_URL =
  baseurlformanageissue + "/getissuesexport";
export const COUNTRY_DROPDOWD =
  DROPDOWN_BASIC_URL + "/getallcountryfordeliveredfile";
export const MAKE_DROPDOWD =
  DROPDOWN_BASIC_URL + "/getallmakeoncountryfordeliveredfile";
export const MODEL_DROPDOWD =
  DROPDOWN_BASIC_URL + "/getallmodelonmakefordeliveredfile";
var baseApiUrlr = process.env.REACT_APP_MANAGE_ISSUE_BASE_URL;
const baseurlr = baseApiUrl + "api";
const basemenu = baseurlr + "/v1";
const baseurlforrolemanagement = baseurlr + "/v1/roles";
export const ROLE_DROPDOWD = baseurlforrolemanagement + "/getallroles";
export const BAU_STATUS = DROPDOWN_BASIC_URL + "/getallbaureasonforfailure";
export const REQUEST_TYPE=DROPDOWN_BASIC_URL+"/getallissuerequesttype"; 
export const MERIT_USERS=DROPDOWN_BASIC_URL+"/getalluserdropdownrolebased"; 
export const REPORTEDBY_DROPDOW_NEW = DROPDOWN_BASIC_URL + "/getallreportedbycondition";
export const PRIORITY_DROPDOW_NEW = DROPDOWN_BASIC_URL + "/issueprioritybasedoncountryidandmakeid";
export const PRIORITY_DROPDOW_L = DROPDOWN_BASIC_URL + "/getallprioritybasedonleasingprovider";

