// Default React, React Components, Router and Services Components
import React, { useEffect, useRef, useState } from "react";
import { Box, Grid, Paper, Tooltip, Typography } from "@mui/material";

// Dummy Data
import { headers, rowData } from "./Data";

// Importing Custom Components
// import AdvanceFilters from './advanceFilters';
import BaseLayout from "components/commonComponents/baseLayout";
import Gridnav from "components/commonComponents/gridnav/gridnav";
import MaterialUIModal from "components/commonComponents/modal/modal";
import MaterialUIButton from "components/commonComponents/button/button";
import AgGrid from "components/commonComponents/grid/agGrid";
import UsePagination from "components/commonComponents/pagination/pagination";

// Importing useStyles
import { styled } from "@mui/material/styles";
import useStyles from "./userManagement.styles";

// Import Material Icons
import RestartAltOutlinedIcon from "@mui/icons-material/RestartAltOutlined";
import FilterListOutlinedIcon from "@mui/icons-material/FilterListOutlined";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import AddBoxOutlinedIcon from "@mui/icons-material/AddBoxOutlined";
import DifferenceOutlinedIcon from "@mui/icons-material/DifferenceOutlined";
import AddNewUser from "./addNewUser";
import {
  addUsers,
  deleteUsers,
  ExportUsers,
  getUsers,
  updateUsers,
  viewUser,
} from "redux/usermanagement/action";
import {
  fetchRole,
  fetchRoles,
  fetchusers,
  fetchUsers,
  fetchUsersid,
} from "redux/app/actions";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import AlertDialog from "components/commonComponents/alertDialog";
import CloseIcon from "@mui/icons-material/Close";
import DoneIcon from "@mui/icons-material/Done";
import { writeFile } from "xlsx";
import { utils } from "xlsx/xlsx";
import moment from "moment";
import ViewUser from "./viewUser";
import EditUser from "./editUser";
import Spinner from "components/commonComponents/spinner/spinner";
import { viewRoles } from "redux/rolemanagement/action";
import { useLocation } from "react-router-dom";
function UserManagement() {
  const dispatch = useDispatch();
  const gridApi = useRef(null);
  const select = useSelector((state) => state.userManagement);
  const id = useSelector((state) => state.project.user);
  const [show, setShow] = useState(false);
  const [selectExport, setselectExport] = useState([]);
  const [rows, setRows] = useState();
  const [search, setSearch] = useState("");
  const [advFilterOpen, setAdvFilterOpen] = useState(false);
  const classes = useStyles();
  const [addIssuseOpen, setAddIssuseOpen] = useState(false);
  const [saveDisabled, setsaveDisabled] = useState(false);
  const [limit, setLimit] = useState(25);
  const [page, setPage] = useState(1);
  const [pageCount, setpageCount] = useState(0);
  const [total, settotal] = useState(0);
  const [totalCount, settotalCount] = useState(0);
  const [totalRecords, settotalRecords] = useState(0);
  const [Deletedata, setDeletedata] = useState({});
  const [showDelete, setshowDelete] = useState(false);
  const [disableedit, setdisableedit] = useState(false);
  const [load, setLoad] = useState(true);
  const [view, SetView] = useState(false);
  const [showEdit, setShowEdit] = useState(false);
  const filterBody = {};
  const selector = JSON.parse(localStorage.getItem("roleData"));

  const [add, setAdd] = useState([]);
  const location = useLocation();
  useEffect(() => {
    getUserManagement(page, limit, "");
    setAdd(
      selector &&
      selector.roleConfigurations.filter((e) =>
        e.menuName === "Manage Issue/Request"
          ? location.pathname === "/manage-issue-/-request" &&
            e.isAdd === true
          : e.menuName.replace("&", "and").toLowerCase() ===
              location.pathname
                .replace("/", "")
                .replace("-", " ")
                .replace("-", " ")
                .replace("/", "#")
                .split("#")[0] && e.isAdd === true
      )
    );
  }, []);
  /******************************************************************************
  Function: getUserManagement
  Argument: page, limit, search
  Return: 
  Usage:
  1.To get all users in grid
  *******************************************************************************/

  const getUserManagement = (page, limit, search) => {
    dispatch(getUsers(page, limit, search, id)).then((res) => {
      if (res) {
        setLoad(false);
        if (res.data.statusCode === 200) {
          if (res.data.result === "No records found") {
            settotalCount(0);
            settotalRecords(0);
            dispatch(fetchUsers([]));
          } else {
            dispatch(fetchUsers(res.data.result));
          }
          setLoad(false);

          settotal(res.data.result.length);
          setpageCount(Math.ceil(total / limit));
          settotalCount(res.data.total);
          settotalRecords(res.data.totalRecords);
        }
      }
    });
  };
  /******************************************************************************
  Function: handlePageClick
  Argument: event, data
  Return: 
  Usage:
  1.To handle page navigation
  *******************************************************************************/

  const handlePageClick = async (event, data) => {
    setLoad(true);
    setPage(data);
    await getUserManagement(data, limit, search);
    gridApi.current.redrawRows(select.usersdata);
  };

  /******************************************************************************
  Function: handleSelect
  Argument: row
  Return: 
  Usage:
  1.To selete data to export
  *******************************************************************************/

  const handleSelect = async (row) => {
    setselectExport(row.map((e) => e.userId));
    console.log(row.length);
    setRows(row.length === 10 || row.length >= 10);
    if (row.length === 0) {
      setShow(false);
    } else {
      setShow(true);
    }
  };

  const exportdata = selectExport.toString();
  const exportIds = exportdata.split(",");
  /******************************************************************************
  Function: handleExport
  Argument: body
  Return: 
  Usage:
  1.To export data in grid
  *******************************************************************************/
  const handleExport = () => {
    console.log(rows);

    setLoad(true);
    dispatch(
      ExportUsers(page, limit, search, rows ? ["All"] : exportIds, id)
    ).then((res) => {
      console.log("export");

      setLoad(false);
      const wb = utils.book_new();
      const ws = utils.json_to_sheet([]);

      utils.sheet_add_aoa(ws, [
        ["First Name", "Last Name", "Email ID", "Role"],
      ]);
      const rows = res.data.result.map((row) => [
        row.firstName,
        row.lastName,
        row.email,
        row.roleName,
      ]);
      utils.sheet_add_json(ws, rows, {
        origin: "A2",
        skipHeader: true,
      });

      utils.book_append_sheet(wb, ws, "Report");
      writeFile(wb, `usermanagement_${moment().format("DMMMyy")}.csv`);
    });
    getUserManagement(page, limit, search);
  };
  /******************************************************************************
  Function: editBody
  Argument: data
  Return:
  Usage:
  1.To set edit data 
  *******************************************************************************/

  const editBody = (data) => {
    dispatch(fetchUsersid(data));
    setShowEdit(true);
    dispatch(viewUser(data.userId)).then((res) => {});
  };
  /******************************************************************************
  Function: viewBody
  Argument: data
  Return:
  Usage:
  1.To set view data 
  *******************************************************************************/

  const viewBody = (data) => {
    dispatch(fetchUsersid(data));
    SetView(true);
    dispatch(viewUser(data.userId)).then((res) => {});
  };

  /******************************************************************************
  Function: EditUsersdata
  Argument: data, userId, roldId
  Return:
  Usage:
  1.To edit already exisitng role 
  *******************************************************************************/

  const EditUsersdata = (data, userId, roldId) => {
    // userId);

    setLoad(true);
    dispatch(updateUsers(data, userId, id)).then(async (res) => {
      if (res.data.statusCode === 200) {
        toast.success("User saved successfully");
        await getUserManagement(page, limit, search);
        if (data.email === localStorage.getItem("email")) {
          localStorage.setItem("firstName", data.firstName);
          localStorage.setItem("lastName", data.lastName);
          dispatch(viewRoles(Number(roldId))).then((res) => {
            localStorage.setItem("roleId", res.data.result.roleId);
            localStorage.setItem("role", res.data.result.roleName);
            localStorage.setItem("roleData", JSON.stringify(res.data.result));
            dispatch(fetchRole(res.data.result));
          });
        }

        setShowEdit(false);
        SetView(false);
        setdisableedit(false);
        setLoad(false);
      } else if (res.data.statusCode === 400) {
        setShowEdit(false);
        SetView(false);
        setdisableedit(false);
        setLoad(false);
        toast.error(res.data.responseException.exceptionMessage);
      } else {
        setShowEdit(false);
        SetView(false);
        setdisableedit(false);
        setLoad(false);
        toast.error("User save failed");
      }

      await gridApi.current.redrawRows(select.usersdata);
    });
  };
  /******************************************************************************
  Function: deleteBody
  Argument: event
  Return:
  Usage:
  1.To set delete data 
  *******************************************************************************/

  const deleteBody = (event) => {
    setDeletedata(event.userId);
    //setRolename(event.);
  };
  /******************************************************************************
  Function: handleDelete
  Argument: userId
  Return:
  Usage:
  1.To delete already exisitng role 
  *******************************************************************************/

  const handleDelete = (userId) => {
    setLoad(true);

    dispatch(deleteUsers(userId, id)).then(async (res) => {
      if (res.data.statusCode === 200) {
        toast.success("User deleted successfully");
        await getUserManagement(page, limit, search);
      } else {
        toast.error("Failed to delete");
      }
      await gridApi.current.redrawRows(select.usersdata);
    });
  };
  /******************************************************************************
  Function: handleGridAction
  Argument:action, selectedRow
  Return:
  Usage:
  1.To handle grid action
  *******************************************************************************/

  const handleGridAction = (action, selectedRow) => {
    switch (action) {
      case "Delete":
        handleDelete(selectedRow);
        break;
      default:
        break;
    }
  };
  const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
    ...theme.typography.body2,
    padding: theme.spacing(2),
    textAlign: "left",
    color: theme.palette.text.secondary,
    boxShadow: "0px 0px 4px 0px rgb(195 0 5 / 10%)",
    position: "relative",
    borderRadius: "14px",
  }));
  /******************************************************************************
  Function: addNewUser
  Argument:data
  Return:
  Usage:
  1.To handle grid action
  *******************************************************************************/

  const addNewUser = (data) => {
    setLoad(true);
    dispatch(addUsers(data, id)).then(async (res) => {
      if (res.data.statusCode === 201) {
        setPage(1);
        await getUserManagement(1, limit, search);
        toast.success("User saved successfully");
        setAddIssuseOpen(false);
        setsaveDisabled(false);
        setLoad(false);
      } else if (res.data.statusCode === 400) {
        setAddIssuseOpen(false);
        setPage(1);
        toast.error(res.data.responseException.exceptionMessage);

        setsaveDisabled(false);
        setLoad(false);
      } else {
        setPage(1);
        setAddIssuseOpen(false);
        setPage(1);
        toast.error("User save failed");
        setsaveDisabled(false);
        setLoad(false);
      }
    });
  };
  const [gridKey, setGridKey] = useState(0);
  const resetAll = async () => {
    // increment the grid key to force a re-render
    setGridKey(gridKey + 1);
    if (search !== "") {
      setPage(1);
      setSearch("");
      await getUserManagement(1, limit, "");
      gridApi.current.api.redrawRows(select.manageIssuedata);
    }

    // increment the grid key to force a re-render
    setGridKey(gridKey + 1);
  };
  return (
    <div>
      {/* Manage Issues Page Start Here */}
      <Box>
        <Spinner open={load} />
        {/* BaseLayout Start Here */}
        <BaseLayout>
          {/* Action Area - Search by Keyword, Total Record Count, Add Issue, Adv. Filter and Export */}
          <Grid
            container
            spacing={2}
            className={classes.pageActionArea}
            alignItems="center"
            justifyContent="flex-start"
          >
            <Grid item xs={12} md={6}>
              <Grid
                container
                alignItems="center"
                justifyContent="left"
                spacing={2}
              >
                <Grid item xs={"auto"}>
                  <Gridnav
                    onChange={async (e) => {
                      setSearch(e.target.value);
                      setPage(1);
                      await getUserManagement(1, limit, e.target.value);
                      gridApi.current.redrawRows(select.usersdata);
                    }}
                    data={search}
                  />
                </Grid>
                <Grid item xs={"auto"}>
                  <Typography
                    variant="caption"
                    display="block"
                    gutterBottom
                    className={classes.totalResults}
                  >
                    Total Results:{" "}
                    <span className={classes.totalValue}>
                      {totalRecords ?? 0}
                    </span>
                    &nbsp;
                    <span className={classes.recordText}>Records</span>
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} md={6}>
              <Grid
                container
                spacing={1.75}
                justifyContent="flex-end"
                alignItems="flex-end"
              >
                <Grid item xs={"auto"}>
                  <MaterialUIButton
                    variant="outlined"
                    message="Clear Filters"
                    startIcon={<RestartAltOutlinedIcon />}
                    onClick={() => resetAll()}
                  />
                </Grid>
                <Grid item xs={"auto"}>
                  <MaterialUIButton
                    variant="outlined"
                    disabled={add.length === 0}
                    onClick={() => setAddIssuseOpen(true)}
                    message="Add New User"
                    startIcon={<AddBoxOutlinedIcon />}
                  />
                </Grid>

                {show === true ? (
                  <Grid item xs={"auto"}>
                    <MaterialUIButton
                      variant="outlined"
                      message="Export"
                      onClick={() => {
                        handleExport();
                        setShow(false);
                      }}
                      startIcon={<FileDownloadOutlinedIcon />}
                    />
                  </Grid>
                ) : (
                  <Grid item xs={"auto"}>
                    <MaterialUIButton
                      variant="outlined"
                      message="Export"
                      disabled
                      startIcon={<FileDownloadOutlinedIcon />}
                    />
                  </Grid>
                )}
              </Grid>
            </Grid>
          </Grid>

          {/* Grid Start here */}
          <Grid
            container
            spacing={2}
            className={classes.pageActionArea}
            alignItems="center"
            justifyContent="flex-start"
            sx={{ p: 0 }}
          >
            <Grid item xs={12} className={classes.fullHeight}>
              <AgGrid
                // onGridReady={onGridReadyManageSource}
                data={select.usersdata}
                //data={rowData}
                header={headers}
                // actionsHandler={handleGridAction}
                //sorting={handleSort}
                selectAll={handleSelect}
                showEdit={() => setShowEdit(true)}
                showDelete={() => setshowDelete(true)}
                showView={() => SetView(true)}
                edit={editBody}
                delete={deleteBody}
                view={viewBody}
                key={gridKey}
              />
              <UsePagination
                pageCount={pageCount}
                count={total}
                t={totalRecords}
                total={totalCount}
                currentPage={page}
                limit={limit}
                value={limit}
                handlePageClick={handlePageClick}
                onChange={(e) => {
                  setLimit(e.target.value);
                  setPage(1);
                  let encodedValue = encodeURIComponent(e.target.value);

                  getUserManagement(1, encodedValue, search);
                  // gridApi.current.redrawRows(select.usersdata);
                }}
              />
            </Grid>
          </Grid>
        </BaseLayout>

        <MaterialUIModal
          open={addIssuseOpen}
          // onClose={() => setAddIssuseOpen(false)}
          anchor="right"
          body={
            <AddNewUser
              close={() => setAddIssuseOpen(false)}
              save={saveDisabled}
              onChangeSave={() => setsaveDisabled(true)}
              addNewUser={addNewUser}
            />
          }
        />
        <MaterialUIModal
          open={showEdit}
          //onClose={() => setShowEditSource(false)}
          anchor="right"
          body={
            <EditUser
              save={disableedit}
              onChangeSave={() => setdisableedit(true)}
              close={() => setShowEdit(false)}
              EditUsersdata={EditUsersdata}
            />
          }
        />
        <MaterialUIModal
          open={view}
          //onClose={() => SetViewsource(false)}
          anchor="right"
          body={
            <ViewUser
              //data={data}
              EditUsersdata={EditUsersdata}
              save={disableedit}
              onChangeSave={() => setdisableedit(true)}
              close={() => SetView(false)}
            />
          }
        />
        <AlertDialog
          open={showDelete}
          onClose={() => setshowDelete(false)}
          // open={isDeleted}
          title={"Confirmation"}
          // maxWidth={"sm"}
          description={`Do you want to delete the User ? `} /* - ${Deletedata} */
          action={
            <Grid
              container
              alignItems="center"
              justifyContent="right"
              spacing={2}
              className={classes.alertDialogbtn}
            >
              <Grid item xs={"auto"}>
                <MaterialUIButton
                  variant="outlined"
                  onClick={() => {
                    handleGridAction("Delete", Deletedata);
                    setshowDelete(false);
                  }}
                  message="Delete"
                  startIcon={<DoneIcon />}
                />
              </Grid>
              <Grid item xs={"auto"}>
                <MaterialUIButton
                  variant="outlined"
                  onClick={() => {
                    setshowDelete(false);
                  }}
                  message="Cancel"
                  startIcon={<CloseIcon />}
                />
              </Grid>
            </Grid>
          }
        />
      </Box>
    </div>
  );
}

export default UserManagement;
