import { Tooltip } from '@mui/material'
import React from 'react'

function MaterialUITooltip(props) {
  return (
    <Tooltip title={props.title} placement={props.placement} arrow>
   {props.body}
  </Tooltip>
  )
}

export default MaterialUITooltip