import { AppActionsTypes } from "./actions-types";

const INITIAL_STATE = {
  ticket: [],
  country: [],
  make: [],
  model: [],
  category: [],
  provider: [],
  type: [],
  project: [],
  priority: [],
  website: [],
  field: [],
  rerun: [],
  meritusers:[],
  openStatus: [],
  complexity: [],
  worktype: [],
  status: [],
  resolutionstatus: [],
  users: [],
  reportedBy: [],
  ticketlist: [],
  developercomplexity: [],
  manageIssuedata: [],
  files: [],
  requestType:[],
  comments: [],
  devfiles: [],
  manageIssuedataid: {},
  attachments: "",
  devAttachments: "",
  addprefilled: {},
  filter: {
    timeline: "Year",
    quater: "",
    startdate: null,
    enddate: null,
    timeLineStart: null,
    timeLineEnd: null,
    ticketId: [],
    country: [],
    make: [],
    model: [],
    issueCategory: [],
    issueType: [],
    priority: [],
    issueField: [],
    issueComplexity: [],

    attachments: "All",
    rerun: 2,
    workType: [],
    status: [],
    reportedBy: [],
    addedBy: [],
    planned: null,
    resolution: null,
    plannedEndDate: null,
    requestTypeId: [],
    resolutionStatus: [],
    resolutionDate: null,
    resolvedBy: [],
    leaseOperator: [],
    exportticketIds: [],
    website: [],
  },
};
const manageIssueReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case AppActionsTypes.FETCH_MANAGE_ISSUE:
      return {
        ...state,
        manageIssuedata: action.payload,
      };
    case AppActionsTypes.PROVIDER:
      return {
        ...state,
        provider: action.payload,
      };
    case AppActionsTypes.ATTACHMENTS:
      return {
        ...state,
        attachments: action.payload,
      };
    case AppActionsTypes.PROJECT:
      return {
        ...state,
        project: action.payload,
      };
    case AppActionsTypes.DEVATTACHMENTS:
      return {
        ...state,
        devAttachments: action.payload,
      };

    case AppActionsTypes.COMPLEXITY:
      return {
        ...state,
        complexity: action.payload,
      };
    case AppActionsTypes.REPORTEDBY:
      return {
        ...state,
        reportedBy: action.payload,
      };
    case AppActionsTypes.COMMENTS:
      return {
        ...state,
        comments: action.payload,
      };
    case AppActionsTypes.OPENSTATUS:
      return {
        ...state,
        openStatus: action.payload,
      };
    case AppActionsTypes.FILTER:
      return {
        ...state,
        filter: action.payload,
      };
    case AppActionsTypes.FILES:
      return {
        ...state,
        files: action.payload,
      };
    case AppActionsTypes.DEVFILES:
      return {
        ...state,
        devfiles: action.payload,
      };
    case AppActionsTypes.FETCH_MANAGE_ISSUE_ID:
      return {
        ...state,
        manageIssuedataid: action.payload,
      };

    case AppActionsTypes.TICKET:
      return {
        ...state,

        ticket: action.payload,
      };
    case AppActionsTypes.COUNTRY:
      return {
        ...state,

        country: action.payload,
      };
    case AppActionsTypes.WEBSITE:
      return {
        ...state,

        website: action.payload,
      };
    case AppActionsTypes.RESOLUTION_STATUS:
      return {
        ...state,

        resolutionstatus: action.payload,
      };
      case AppActionsTypes.REQUEST_TYPE_DROPDOWN:
      return {
        ...state,

        requestType: action.payload,
      };
    case AppActionsTypes.TICKET_LIST:
      return {
        ...state,

        ticketlist: action.payload,
      };
    case AppActionsTypes.USERS:
      return {
        ...state,

        users: action.payload,
      };
      case AppActionsTypes.MUSER:
        return {
          ...state,
  
          meritusers: action.payload,
        };
      
    case AppActionsTypes.MAKE:
      return {
        ...state,

        make: action.payload,
      };
    case AppActionsTypes.MODEL:
      return {
        ...state,

        model: action.payload,
      };
    case AppActionsTypes.CATEGORY:
      return {
        ...state,

        category: action.payload,
      };
    case AppActionsTypes.TYPE:
      return {
        ...state,

        type: action.payload,
      };
    case AppActionsTypes.PRIORITY:
      return {
        ...state,

        priority: action.payload,
      };
    case AppActionsTypes.FIELD:
      return {
        ...state,

        field: action.payload,
      };
    case AppActionsTypes.RERUN:
      return {
        ...state,

        rerun: action.payload,
      };

    case AppActionsTypes.WORKTYPE:
      return {
        ...state,

        worktype: action.payload,
      };
    case AppActionsTypes.STATUS:
      return {
        ...state,

        status: action.payload,
      };
    case AppActionsTypes.DEVELOPER_COMPLEXITY:
      return {
        ...state,

        developercomplexity: action.payload,
      };
    case AppActionsTypes.PREFILED:
      return {
        ...state,

        addprefilled: action.payload,
      };

    default:
      return state;
  }
};

export default manageIssueReducer;
