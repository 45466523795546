import MultiFloatFilter from "./MultiFloatFilter";
export const headers = [
  {
    headerName: "Ticket ID",
    field: "ticketId",
    sortable: true,
    filter: MultiFloatFilter,
    filterParams: "ticketId",

    headerCheckboxSelection: true,
    checkboxSelection: true,
    cellRenderer: "actionCellRenderer",

    lockPosition: "left",
    cellRendererParams: {
      actionType: "manageissue",
    },
    width: 155,
    minWidth: 155,
  },

  {
    headerName: "Date Raised",
    sortable: true,
    field: "dateRaised",
    filter: MultiFloatFilter,
    cellRenderer: "activeCellRenderer",
    cellRendererParams: {
      actionType: "format",
    },
    lockPosition: "left",
    filterParams: "dateRaised",
    minWidth: 165,
    //maxWidth: 175,
  },

  {
    headerName: "File Uploaded Date",
    field: "dateofFile",
    filter: MultiFloatFilter,
    filterParams: "dateofFile",
    cellRenderer: "activeCellRenderer",

    lockPosition: "left",
    cellRendererParams: {
      actionType: "formatfile",
    },
    sortable: true,
    width: 205,
    minWidth: 205,
  },
  {
    headerName: "Project Name",
    field: "projectName",
    filter: MultiFloatFilter,
    filterParams: "projectName",
    lockPosition: "left",
    sortable: true,
    width: 205,
    minWidth: 205,
  },
  {
    headerName: "Country",
    field: "country",
    filter: MultiFloatFilter,
    filterParams: "country",
    lockPosition: "left",
    sortable: true,
    minWidth: 130,
    //maxWidth: 120,
    suppressRowTransform: true,
  },

  {
    headerName: "Make",

    lockPosition: "left",
    field: "make",
    filter: MultiFloatFilter,
    filterParams: "make",
    width: 180,
    minWidth: 180,
    //maxWidth: 120,
    sortable: true,
    // cellClass: "trimText",
    cellRenderer: "activeCellRenderer",
    cellRendererParams: {
      actionType: "formatmake",
    },
  },
  {
    headerName: "Model",
    lockPosition: "left",
    field: "model",
    filter: MultiFloatFilter,
    filterParams: "model",
    wrapText: true,
    autoHeight: true,
    width: 180,
    minWidth: 180,
    sortable: true,

    cellClass: "trimText",
    cellRenderer: "activeCellRenderer",
    cellRendererParams: {
      actionType: "formatmodel",
    },
  },

  {
    headerName: "Issue Type",
    field: "issueType",
    filter: MultiFloatFilter,
    filterParams: "issueType",
    minWidth: 180,
    //maxWidth: 140,

    lockPosition: "left",
    sortable: true,
  },
  {
    headerName: "Business Priority",
    field: "issuePriority",
    cellRenderer: "activeCellRenderer",
    cellRendererParams: {
      actionType: "priorityissue",
    },

    lockPosition: "left",
    filter: MultiFloatFilter,
    filterParams: "issuePriority",
    minWidth: 180,
    //maxWidth: 150,
    sortable: true,
  },
  {
    headerName: "Issue Field",
    field: "issueField",
    filter: MultiFloatFilter,
    filterParams: "issueField",
    cellRenderer: "activeCellRenderer",
    cellRendererParams: {
      actionType: "formatissuefield",
    },

    lockPosition: "left",
    minWidth: 200,
    //maxWidth: 210,
    sortable: true,
  },
  {
    headerName: "Request Type",
    field: "requestTypeName",
    filter: MultiFloatFilter,
    filterParams: "requestTypeName",

    lockPosition: "left",
    minWidth: 200,
    //maxWidth: 210,
    sortable: true,
  },
  {
    headerName: "Feedback",
    field: "feedback",
    filter: MultiFloatFilter,
    filterParams: "feedback",

    width: 300,
    minWidth: 300,
    sortable: true,
    cellClass: "trimText",
    lockPosition: "left",
    wrapText: true,
    autoHeight: true,
    // tooltipField: "feedback",
    cellRenderer: "activeCellRenderer",
    cellRendererParams: {
      actionType: "formatfeedback",
    },
  },
  {
    headerName: "Attachments",
    field: "attachments",
    cellRenderer: "activeCellRenderer",
    cellRendererParams: {
      actionType: "attachment",
    },
    width: 360,
    minWidth: 360,
    sortable: true,

    lockPosition: "left",
  },

  {
    headerName: "Rerun",

    lockPosition: "left",
    field: "rerun",
    filter: MultiFloatFilter,
    filterParams: "rerun",
    minWidth: 140,
    //maxWidth: 110,
    sortable: true,
  },
  {
    headerName: "Merit Justification",
    lockPosition: "left",
    field: "meritJustification",
    filter: MultiFloatFilter,

    filterParams: "meritJustification",
    /*  cellStyle: {
      "text-overflow": "ellipsis",
      "white-space": "nowrap",
      overflow: "hidden",
      padding: 0,
    }, */
    cellRenderer: "activeCellRenderer",
    cellRendererParams: {
      actionType: "formatmeritJustification",
    },
    width: 300,
    minWidth: 300,

    cellClass: "trimText",
    //tooltipField: "meritJustification",
    sortable: true,
    wrapText: true,
    autoHeight: true,
  },
  // {
  //   headerName: "Work Type",

  //   lockPosition: "left",
  //   field: "workType.workType1",
  //   filter: MultiFloatFilter,

  //   filterParams: "workType",
  //   minWidth: 150,
  //   //maxWidth: 190,
  //   sortable: true,
  // },
  {
    headerName: "Status",

    lockPosition: "left",
    field: "status",
    filter: MultiFloatFilter,
    filterParams: "status",
    minWidth: 160,
    //maxWidth: 120,
    sortable: true,
  },

  {
    headerName: "Reported By",

    lockPosition: "left",
    field: "reportedBy",
    filter: MultiFloatFilter,
    filterParams: "reportedBy",
    minWidth: 220,
    //maxWidth: 220,
    sortable: true,
  },

  {
    headerName: "Added By",

    lockPosition: "left",
    field: "addedBy",
    filter: MultiFloatFilter,
    filterParams: "addedBy",
    minWidth: 220,
    //maxWidth: 220,
    sortable: true,
  },
  {
    headerName: "Time To First Response TAT",

    lockPosition: "left",
    field: "firstResponseTAT",
    filter: MultiFloatFilter,
    filterParams: "firstResponseTAT",
    cellRenderer: "activeCellRenderer",
    cellRendererParams: {
      actionType: "formattat",
    },
    minWidth: 200,
    //maxWidth: 185,
    sortable: true,
  },
  {
    headerName: "Time To First Response",

    lockPosition: "left",
    field: "plannedEndDate",
    filter: MultiFloatFilter,
    filterParams: "plannedEndDate",
    cellRenderer: "activeCellRenderer",
    cellRendererParams: {
      actionType: "formatplanned",
    },
    minWidth: 175,
    //maxWidth: 185,
    sortable: true,
  },
  {
    headerName: "Assigned To",

    lockPosition: "left",
    field: "assignedTo",
    filter: MultiFloatFilter,
    filterParams: "assignedTo",
    minWidth: 220,
    //maxWidth: 220,
    sortable: true,
  },

  {
    headerName: "Assigned Date",

    lockPosition: "left",
    field: "assignedDate",
    filter: MultiFloatFilter,
    filterParams: "assignedDate",
    cellRenderer: "activeCellRenderer",
    cellRendererParams: {
      actionType: "formatassign",
    },
    minWidth: 170,
    //maxWidth: 170,
    sortable: true,
  },
  {
    headerName: "Issue Category",
    field: "issueCategory",
    filter: MultiFloatFilter,
    filterParams: "issueCategory",
    minWidth: 220,
    //maxWidth: 220,
    sortable: true,

    lockPosition: "left",
  },
  {
    headerName: "Scrapper Complexity",
    lockPosition: "left",
    field: "complexity",
    filter: MultiFloatFilter,

    filterParams: "complexity",
    minWidth: 250,
    //maxWidth: 250,
    sortable: true,
  },
  {
    headerName: "Time to Resolution",

    lockPosition: "left",
    field: "resolutionDate",
    filter: MultiFloatFilter,
    filterParams: "resolutionDate",
    cellRenderer: "activeCellRenderer",
    cellRendererParams: {
      actionType: "formatresolution",
    },

    width: 175,
    minWidth: 175,

    sortable: true,
  },
  {
    headerName: "Resolution Status",

    lockPosition: "left",
    field: "resolutionStatus",
    filter: MultiFloatFilter,

    filterParams: "resolutionStatus",
    minWidth: 200,
    cellRenderer: "activeCellRenderer",
    cellRendererParams: {
      actionType: "formatresolutionstatus",
    },
    //maxWidth: 250,
    sortable: true,
  },
  {
    headerName: "Actual Closed Date",

    lockPosition: "left",
    field: "actualClosedDate",
    filter: MultiFloatFilter,
    cellRenderer: "activeCellRenderer",
    filterParams: "actualClosedDate",
    cellRendererParams: {
      actionType: "formatclosedfate",
    },

    minWidth: 200,
    //maxWidth: 250,
    sortable: true,
  },
  {
    headerName: "Resolution Attachment",
    field: "devAttachments",
    cellRenderer: "activeCellRenderer",
    cellRendererParams: {
      actionType: "devattachment",
    },
    width: 360,
    minWidth: 360,
    sortable: true,

    lockPosition: "left",
  },
  {
    headerName: "Developer Comments",
    lockPosition: "left",
    field: "developerComments",
    //filter: MultiFloatFilter,
    //filterParams: "developerComments",
    cellRenderer: "activeCellRenderer",
    cellRendererParams: {
      actionType: "devcomments",
    },
    width: 360,
    minWidth: 360,
    cellClass: "trimText",
    sortable: true,
    wrapText: true,
    autoHeight: true,
  },

  {
    headerName: "Action",
    lockPosition: "left",
    field: "bnF_Chapter_Key_Formulary",
    cellRenderer: "iconCellRenderer",
    cellRendererParams: {
      actionType: "qcaction",
    },
    width: 170,
    minWidth: 170,
    maxWidth: 170,

    cellClass: ["ag-cell-align-center", "locked-col"],
    // cellStyle: { color: 'red', 'background-color': 'green' }

    headerComponentParams: {
      template:
        '<div class="ag-cell-label-container" role="presentation">' +
        '  <div ref="eLabel" class="ag-header-cell-label" role="presentation">' +
        '    <div style="padding-left:50px"><span ref="eText" class="ag-header-cell-text" role="columnheader"></span></div>' +
        "  </div>" +
        "</div>",
    },
  },
];
export const headers1 = [
  {
    headerName: "Ticket ID",
    field: "ticketId",
    sortable: true,
    filter: MultiFloatFilter,
    filterParams: "ticketId",

    headerCheckboxSelection: true,
    checkboxSelection: true,
    cellRenderer: "actionCellRenderer",

    lockPosition: "left",
    cellRendererParams: {
      actionType: "manageissue",
    },
    width: 155,
    minWidth: 155,
  },

  {
    headerName: "Date Raised",
    sortable: true,
    field: "dateRaised",
    filter: MultiFloatFilter,
    cellRenderer: "activeCellRenderer",
    cellRendererParams: {
      actionType: "format",
    },
    lockPosition: "left",
    filterParams: "dateRaised",
    minWidth: 165,
    //maxWidth: 175,
  },

  {
    headerName: "File Uploaded Date",
    field: "dateofFile",
    filter: MultiFloatFilter,
    filterParams: "dateofFile",
    cellRenderer: "activeCellRenderer",

    lockPosition: "left",
    cellRendererParams: {
      actionType: "formatfile",
    },
    sortable: true,
    width: 205,
    minWidth: 205,
  },
  // {
  //   headerName: "Project Name",
  //   field: "projectName",
  //   filter: MultiFloatFilter,
  //   filterParams: "projectName",
  //   lockPosition: "left",
  //   sortable: true,
  //   width: 205,
  //   minWidth: 205,
  // },
  {
    headerName: "Country",
    field: "country",
    filter: MultiFloatFilter,
    filterParams: "country",
    lockPosition: "left",
    sortable: true,
    minWidth: 130,
    //maxWidth: 120,
    suppressRowTransform: true,
  },
  {
    headerName: "Leasing Provider",
    lockPosition: "left",
    field: "leasingProviderName",
    filter: MultiFloatFilter,
    filterParams: "leasingProviderName",
    wrapText: true,
    autoHeight: true,
    width: 190,
    minWidth: 190,
    sortable: true,

    cellClass: "trimText",
  },

  {
    headerName: "Make",

    lockPosition: "left",
    field: "make",
    filter: MultiFloatFilter,
    filterParams: "make",
    width: 180,
    minWidth: 180,
    sortable: true,
    // cellClass: "trimText",
    cellRenderer: "activeCellRenderer",
    cellRendererParams: {
      actionType: "formatmake",
    },
  },
  {
    headerName: "Model",
    lockPosition: "left",
    field: "model",
    filter: MultiFloatFilter,
    filterParams: "model",
    wrapText: true,
    autoHeight: true,
    width: 180,
    minWidth: 180,
    sortable: true,

    cellClass: "trimText",
    cellRenderer: "activeCellRenderer",
    cellRendererParams: {
      actionType: "formatmodel",
    },
  },

  {
    headerName: "Issue Type",
    field: "issueType",
    filter: MultiFloatFilter,
    filterParams: "issueType",
    minWidth: 180,
    //maxWidth: 140,

    lockPosition: "left",
    sortable: true,
  },
  {
    headerName: "Business Priority",
    field: "issuePriority",
    cellRenderer: "activeCellRenderer",
    cellRendererParams: {
      actionType: "priorityissue",
    },

    lockPosition: "left",
    filter: MultiFloatFilter,
    filterParams: "issuePriority",
    minWidth: 180,
    //maxWidth: 150,
    sortable: true,
  },
  {
    headerName: "Issue Field",
    field: "issueField",
    filter: MultiFloatFilter,
    filterParams: "issueField",
    cellRenderer: "activeCellRenderer",
    cellRendererParams: {
      actionType: "formatissuefield",
    },

    lockPosition: "left",
    minWidth: 200,
    //maxWidth: 210,
    sortable: true,
  },
  {
    headerName: "Request Type",
    field: "requestTypeName",
    filter: MultiFloatFilter,
    filterParams: "requestTypeName",

    lockPosition: "left",
    minWidth: 200,
    //maxWidth: 210,
    sortable: true,
  },
  {
    headerName: "Feedback",
    field: "feedback",
    filter: MultiFloatFilter,
    filterParams: "feedback",

    width: 300,
    minWidth: 300,
    sortable: true,
    cellClass: "trimText",
    lockPosition: "left",
    wrapText: true,
    autoHeight: true,
    // tooltipField: "feedback",
    cellRenderer: "activeCellRenderer",
    cellRendererParams: {
      actionType: "formatfeedback",
    },
  },
  {
    headerName: "Attachments",
    field: "attachments",
    cellRenderer: "activeCellRenderer",
    cellRendererParams: {
      actionType: "attachment",
    },
    width: 360,
    minWidth: 360,
    sortable: true,

    lockPosition: "left",
  },

  {
    headerName: "Rerun",

    lockPosition: "left",
    field: "rerun",
    filter: MultiFloatFilter,
    filterParams: "rerun",
    minWidth: 140,
    //maxWidth: 110,
    sortable: true,
  },
  {
    headerName: "Merit Justification",
    lockPosition: "left",
    field: "meritJustification",
    filter: MultiFloatFilter,

    filterParams: "meritJustification",
    /*  cellStyle: {
      "text-overflow": "ellipsis",
      "white-space": "nowrap",
      overflow: "hidden",
      padding: 0,
    }, */
    cellRenderer: "activeCellRenderer",
    cellRendererParams: {
      actionType: "formatmeritJustification",
    },
    width: 300,
    minWidth: 300,

    cellClass: "trimText",
    //tooltipField: "meritJustification",
    sortable: true,
    wrapText: true,
    autoHeight: true,
  },
  // {
  //   headerName: "Work Type",

  //   lockPosition: "left",
  //   field: "workType.workType1",
  //   filter: MultiFloatFilter,

  //   filterParams: "workType",
  //   minWidth: 150,
  //   //maxWidth: 190,
  //   sortable: true,
  // },
  {
    headerName: "Status",

    lockPosition: "left",
    field: "status",
    filter: MultiFloatFilter,
    filterParams: "status",
    minWidth: 160,
    //maxWidth: 120,
    sortable: true,
  },

  {
    headerName: "Reported By",

    lockPosition: "left",
    field: "reportedBy",
    filter: MultiFloatFilter,
    filterParams: "reportedBy",
    minWidth: 220,
    //maxWidth: 220,
    sortable: true,
  },

  {
    headerName: "Added By",

    lockPosition: "left",
    field: "addedBy",
    filter: MultiFloatFilter,
    filterParams: "addedBy",
    minWidth: 220,
    //maxWidth: 220,
    sortable: true,
  },
  {
    headerName: "Time To First Response TAT",

    lockPosition: "left",
    field: "firstResponseTAT",
    filter: MultiFloatFilter,
    filterParams: "firstResponseTAT",
    cellRenderer: "activeCellRenderer",
    cellRendererParams: {
      actionType: "formattat",
    },
    minWidth: 200,
    //maxWidth: 185,
    sortable: true,
  },
  {
    headerName: "Time To First Response",

    lockPosition: "left",
    field: "plannedEndDate",
    filter: MultiFloatFilter,
    filterParams: "plannedEndDate",
    cellRenderer: "activeCellRenderer",
    cellRendererParams: {
      actionType: "formatplanned",
    },
    minWidth: 175,
    //maxWidth: 185,
    sortable: true,
  },
  {
    headerName: "Assigned To",

    lockPosition: "left",
    field: "assignedTo",
    filter: MultiFloatFilter,
    filterParams: "assignedTo",
    minWidth: 220,
    //maxWidth: 220,
    sortable: true,
  },

  {
    headerName: "Assigned Date",

    lockPosition: "left",
    field: "assignedDate",
    filter: MultiFloatFilter,
    filterParams: "assignedDate",
    cellRenderer: "activeCellRenderer",
    cellRendererParams: {
      actionType: "formatassign",
    },
    minWidth: 170,
    //maxWidth: 170,
    sortable: true,
  },
  {
    headerName: "Issue Category",
    field: "issueCategory",
    filter: MultiFloatFilter,
    filterParams: "issueCategory",
    minWidth: 220,
    //maxWidth: 220,
    sortable: true,

    lockPosition: "left",
  },
  {
    headerName: "Scrapper Complexity",
    lockPosition: "left",
    field: "complexity",
    filter: MultiFloatFilter,

    filterParams: "complexity",
    minWidth: 250,
    //maxWidth: 250,
    sortable: true,
  },
  {
    headerName: "Time to Resolution",

    lockPosition: "left",
    field: "resolutionDate",
    filter: MultiFloatFilter,
    filterParams: "resolutionDate",
    cellRenderer: "activeCellRenderer",
    cellRendererParams: {
      actionType: "formatresolution",
    },

    width: 175,
    minWidth: 175,

    sortable: true,
  },
  {
    headerName: "Resolution Status",

    lockPosition: "left",
    field: "resolutionStatus",
    filter: MultiFloatFilter,

    filterParams: "resolutionStatus",
    minWidth: 200,
    cellRenderer: "activeCellRenderer",
    cellRendererParams: {
      actionType: "formatresolutionstatus",
    },
    //maxWidth: 250,
    sortable: true,
  },
  {
    headerName: "Actual Closed Date",

    lockPosition: "left",
    field: "actualClosedDate",
    filter: MultiFloatFilter,
    cellRenderer: "activeCellRenderer",
    filterParams: "actualClosedDate",
    cellRendererParams: {
      actionType: "formatclosedfate",
    },

    minWidth: 200,
    //maxWidth: 250,
    sortable: true,
  },
  {
    headerName: "Resolution Attachment",
    field: "devAttachments",
    cellRenderer: "activeCellRenderer",
    cellRendererParams: {
      actionType: "devattachment",
    },
    width: 360,
    minWidth: 360,
    sortable: true,

    lockPosition: "left",
  },
  {
    headerName: "Developer Comments",
    lockPosition: "left",
    field: "developerComments",
    //filter: MultiFloatFilter,
    //filterParams: "developerComments",
    cellRenderer: "activeCellRenderer",
    cellRendererParams: {
      actionType: "devcomments",
    },
    width: 360,
    minWidth: 360,
    cellClass: "trimText",
    sortable: true,
    wrapText: true,
    autoHeight: true,
  },

  {
    headerName: "Action",
    lockPosition: "left",
    field: "bnF_Chapter_Key_Formulary",
    cellRenderer: "iconCellRenderer",
    cellRendererParams: {
      actionType: "qcaction",
    },
    width: 170,
    minWidth: 170,
    maxWidth: 170,

    cellClass: ["ag-cell-align-center", "locked-col"],
    // cellStyle: { color: 'red', 'background-color': 'green' }

    headerComponentParams: {
      template:
        '<div class="ag-cell-label-container" role="presentation">' +
        '  <div ref="eLabel" class="ag-header-cell-label" role="presentation">' +
        '    <div style="padding-left:50px"><span ref="eText" class="ag-header-cell-text" role="columnheader"></span></div>' +
        "  </div>" +
        "</div>",
    },
  },
];
export const headers2 = [
  {
    headerName: "Ticket ID",
    field: "ticketId",
    sortable: true,
    filter: MultiFloatFilter,
    filterParams: "ticketId",

    headerCheckboxSelection: true,
    checkboxSelection: true,
    cellRenderer: "actionCellRenderer",

    lockPosition: "left",
    cellRendererParams: {
      actionType: "manageissue",
    },
    width: 155,
    minWidth: 155,
  },

  {
    headerName: "Date Raised",
    sortable: true,
    field: "dateRaised",
    filter: MultiFloatFilter,
    cellRenderer: "activeCellRenderer",
    cellRendererParams: {
      actionType: "format",
    },
    lockPosition: "left",
    filterParams: "dateRaised",
    minWidth: 165,
    //maxWidth: 175,
  },

  {
    headerName: "File Uploaded Date",
    field: "dateofFile",
    filter: MultiFloatFilter,
    filterParams: "dateofFile",
    cellRenderer: "activeCellRenderer",

    lockPosition: "left",
    cellRendererParams: {
      actionType: "formatfile",
    },
    sortable: true,
    width: 205,
    minWidth: 205,
  },
  {
    headerName: "Project Name",
    field: "projectName",
    filter: MultiFloatFilter,
    filterParams: "projectName",
    lockPosition: "left",
    sortable: true,
    width: 205,
    minWidth: 205,
  },
  {
    headerName: "Country",
    field: "country",
    filter: MultiFloatFilter,
    filterParams: "country",
    lockPosition: "left",
    sortable: true,
    minWidth: 130,
    //maxWidth: 120,
    suppressRowTransform: true,
  },

  {
    headerName: "Website",
    field: "website",
    filter: MultiFloatFilter,
    filterParams: "website",
    lockPosition: "left",
    sortable: true,
    minWidth: 130,
    //maxWidth: 120,
    suppressRowTransform: true,
  },
  {
    headerName: "Make",

    lockPosition: "left",
    field: "make",
    filter: MultiFloatFilter,
    filterParams: "make",
    width: 180,
    minWidth: 180,
    sortable: true,
    // cellClass: "trimText",
    cellRenderer: "activeCellRenderer",
    cellRendererParams: {
      actionType: "formatmake",
    },
  },
  {
    headerName: "Model",
    lockPosition: "left",
    field: "model",
    filter: MultiFloatFilter,
    filterParams: "model",
    wrapText: true,
    autoHeight: true,
    width: 180,
    minWidth: 180,
    sortable: true,

    cellClass: "trimText",
    cellRenderer: "activeCellRenderer",
    cellRendererParams: {
      actionType: "formatmodel",
    },
  },
  {
    headerName: "Issue Type",
    field: "issueType",
    filter: MultiFloatFilter,
    filterParams: "issueType",
    minWidth: 180,
    //maxWidth: 140,

    lockPosition: "left",
    sortable: true,
  },
  {
    headerName: "Business Priority",
    field: "issuePriority",
    cellRenderer: "activeCellRenderer",
    cellRendererParams: {
      actionType: "priorityissue",
    },

    lockPosition: "left",
    filter: MultiFloatFilter,
    filterParams: "issuePriority",
    minWidth: 180,
    //maxWidth: 150,
    sortable: true,
  },
  {
    headerName: "Issue Field",
    field: "issueField",
    filter: MultiFloatFilter,
    filterParams: "issueField",
    cellRenderer: "activeCellRenderer",
    cellRendererParams: {
      actionType: "formatissuefield",
    },

    lockPosition: "left",
    minWidth: 200,
    //maxWidth: 210,
    sortable: true,
  },
  {
    headerName: "Request Type",
    field: "requestTypeName",
    filter: MultiFloatFilter,
    filterParams: "requestTypeName",

    lockPosition: "left",
    minWidth: 200,
    //maxWidth: 210,
    sortable: true,
  },
  {
    headerName: "Feedback",
    field: "feedback",
    filter: MultiFloatFilter,
    filterParams: "feedback",

    width: 300,
    minWidth: 300,
    sortable: true,
    cellClass: "trimText",
    lockPosition: "left",
    wrapText: true,
    autoHeight: true,
    // tooltipField: "feedback",
    cellRenderer: "activeCellRenderer",
    cellRendererParams: {
      actionType: "formatfeedback",
    },
  },
  {
    headerName: "Attachments",
    field: "attachments",
    cellRenderer: "activeCellRenderer",
    cellRendererParams: {
      actionType: "attachment",
    },
    width: 360,
    minWidth: 360,
    sortable: true,

    lockPosition: "left",
  },

  {
    headerName: "Rerun",

    lockPosition: "left",
    field: "rerun",
    filter: MultiFloatFilter,
    filterParams: "rerun",
    minWidth: 140,
    //maxWidth: 110,
    sortable: true,
  },
  {
    headerName: "Merit Justification",
    lockPosition: "left",
    field: "meritJustification",
    filter: MultiFloatFilter,

    filterParams: "meritJustification",
    /*  cellStyle: {
      "text-overflow": "ellipsis",
      "white-space": "nowrap",
      overflow: "hidden",
      padding: 0,
    }, */
    cellRenderer: "activeCellRenderer",
    cellRendererParams: {
      actionType: "formatmeritJustification",
    },
    width: 300,
    minWidth: 300,

    cellClass: "trimText",
    //tooltipField: "meritJustification",
    sortable: true,
    wrapText: true,
    autoHeight: true,
  },
  // {
  //   headerName: "Work Type",

  //   lockPosition: "left",
  //   field: "workType.workType1",
  //   filter: MultiFloatFilter,

  //   filterParams: "workType",
  //   minWidth: 150,
  //   //maxWidth: 190,
  //   sortable: true,
  // },
  {
    headerName: "Status",

    lockPosition: "left",
    field: "status",
    filter: MultiFloatFilter,
    filterParams: "status",
    minWidth: 160,
    //maxWidth: 120,
    sortable: true,
  },

  {
    headerName: "Reported By",

    lockPosition: "left",
    field: "reportedBy",
    filter: MultiFloatFilter,
    filterParams: "reportedBy",
    minWidth: 220,
    //maxWidth: 220,
    sortable: true,
  },

  {
    headerName: "Added By",

    lockPosition: "left",
    field: "addedBy",
    filter: MultiFloatFilter,
    filterParams: "addedBy",
    minWidth: 220,
    //maxWidth: 220,
    sortable: true,
  },
  {
    headerName: "Time To First Response TAT",

    lockPosition: "left",
    field: "firstResponseTAT",
    filter: MultiFloatFilter,
    filterParams: "firstResponseTAT",
    cellRenderer: "activeCellRenderer",
    cellRendererParams: {
      actionType: "formattat",
    },
    minWidth: 200,
    //maxWidth: 185,
    sortable: true,
  },
  {
    headerName: "Time To First Response",

    lockPosition: "left",
    field: "plannedEndDate",
    filter: MultiFloatFilter,
    filterParams: "plannedEndDate",
    cellRenderer: "activeCellRenderer",
    cellRendererParams: {
      actionType: "formatplanned",
    },
    minWidth: 175,
    //maxWidth: 185,
    sortable: true,
  },
  {
    headerName: "Assigned To",

    lockPosition: "left",
    field: "assignedTo",
    filter: MultiFloatFilter,
    filterParams: "assignedTo",
    minWidth: 220,
    //maxWidth: 220,
    sortable: true,
  },

  {
    headerName: "Assigned Date",

    lockPosition: "left",
    field: "assignedDate",
    filter: MultiFloatFilter,
    filterParams: "assignedDate",
    cellRenderer: "activeCellRenderer",
    cellRendererParams: {
      actionType: "formatassign",
    },
    minWidth: 170,
    //maxWidth: 170,
    sortable: true,
  },
  {
    headerName: "Issue Category",
    field: "issueCategory",
    filter: MultiFloatFilter,
    filterParams: "issueCategory",
    minWidth: 220,
    //maxWidth: 220,
    sortable: true,

    lockPosition: "left",
  },
  {
    headerName: "Scrapper Complexity",
    lockPosition: "left",
    field: "complexity",
    filter: MultiFloatFilter,

    filterParams: "complexity",
    minWidth: 250,
    //maxWidth: 250,
    sortable: true,
  },
  {
    headerName: "Time to Resolution",

    lockPosition: "left",
    field: "resolutionDate",
    filter: MultiFloatFilter,
    filterParams: "resolutionDate",
    cellRenderer: "activeCellRenderer",
    cellRendererParams: {
      actionType: "formatresolution",
    },

    width: 175,
    minWidth: 175,

    sortable: true,
  },
  {
    headerName: "Resolution Status",

    lockPosition: "left",
    field: "resolutionStatus",
    filter: MultiFloatFilter,

    filterParams: "resolutionStatus",
    minWidth: 200,
    cellRenderer: "activeCellRenderer",
    cellRendererParams: {
      actionType: "formatresolutionstatus",
    },
    //maxWidth: 250,
    sortable: true,
  },
  {
    headerName: "Actual Closed Date",

    lockPosition: "left",
    field: "actualClosedDate",
    filter: MultiFloatFilter,
    cellRenderer: "activeCellRenderer",
    filterParams: "actualClosedDate",
    cellRendererParams: {
      actionType: "formatclosedfate",
    },

    minWidth: 200,
    //maxWidth: 250,
    sortable: true,
  },
  {
    headerName: "Resolution Attachment",
    field: "devAttachments",
    cellRenderer: "activeCellRenderer",
    cellRendererParams: {
      actionType: "devattachment",
    },
    width: 360,
    minWidth: 360,
    sortable: true,

    lockPosition: "left",
  },
  {
    headerName: "Developer Comments",
    lockPosition: "left",
    field: "developerComments",
    //filter: MultiFloatFilter,
    //filterParams: "developerComments",
    cellRenderer: "activeCellRenderer",
    cellRendererParams: {
      actionType: "devcomments",
    },
    width: 360,
    minWidth: 360,
    cellClass: "trimText",
    sortable: true,
    wrapText: true,
    autoHeight: true,
  },

  {
    headerName: "Action",
    lockPosition: "left",
    field: "bnF_Chapter_Key_Formulary",
    cellRenderer: "iconCellRenderer",
    cellRendererParams: {
      actionType: "qcaction",
    },
    width: 170,
    minWidth: 170,
    maxWidth: 170,

    cellClass: ["ag-cell-align-center", "locked-col"],
    // cellStyle: { color: 'red', 'background-color': 'green' }

    headerComponentParams: {
      template:
        '<div class="ag-cell-label-container" role="presentation">' +
        '  <div ref="eLabel" class="ag-header-cell-label" role="presentation">' +
        '    <div style="padding-left:50px"><span ref="eText" class="ag-header-cell-text" role="columnheader"></span></div>' +
        "  </div>" +
        "</div>",
    },
  },
];
