import React, { Component, useEffect, useMemo } from "react";
import PropTypes from "prop-types";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import IconCellRender from "./cellRenders/iconCellRender";
import SelectCellRenderer from "./cellRenders/selectCellRender";
import HeaderCellRender from "./cellRenders/headerCellRender";
import ActiveCellRenderer from "./cellRenders/activeCellRenderer";
import ActionCellRender from "./cellRenders/actionCellRender";
import AnchorCellRender from "./cellRenders/anchorCellRender";
import { loader } from "assets/index";
import { CircularProgress } from "@mui/material";

export default class agGrid extends Component {
  state = {
    header: [],
    data: [],
    filter: true,
    components: {
      //agColumnHeader: HeaderCellRender,
    },
  };

  // componentWillMount() {}

  componentDidMount() {}

  //componentWillReceiveProps(nextProps) {}

  //shouldComponentUpdate(nextProps, nextState) {}

  //componentWillUpdate(nextProps, nextState) {}

  //componentDidUpdate(prevProps, prevState) {}

  //UNSAFE_componentWillUpdate() {}
  drilldown = (e) => {
    //this.props.setshowEdit(e.ticketId);
  };
  handleDelete = (data) => {
    this.props.delete(data);
  };
  handleView = (data) => {
    this.props.view(data);
  };
  handleDownload=(url,name)=>{
    this.props.download(url,name)
  }
  handleClone = (data) => {
    this.props.clone(data);
  };
  handleEdit = (data) => {
    this.props.edit(data);
  };
  setView = () => {
    this.props.showView();
  };
  setDelete = () => {
    this.props.showDelete();
  };
  handleSelection = (select, name, selectedRow) => {
    this.props.handleSelect(select, name, selectedRow);
    this.gridApi.setRowData(this.props.data);
  };
  setDeletedisable = () => {
    this.props.hideDelete();
  };
  setEdit = () => {
    this.props.showEdit();
  };
  setLoad = () => {
    this.props.load(this.gridApi.showLoadingOverlay());
  };
  /* setSorting = (field, sort) => {
    this.props.sorting(field, sort);
  }; */

  onSelectionChanged = (event) => {
    this.props.selectAll(event.api.getSelectedRows());
  };
  // onGridReady = (params) => {
  //   this.gridApi = params.api;
  //   this.gridColumnApi = params.columnApi;

  //    params.api.setRowData(this.props.data);
  //    const allColumnIds = [];
  //    this.gridColumnApi.getColumns().forEach((column) => {
  //     allColumnIds.push(column.getId());
  //   });
  //   this.gridColumnApi.autoSizeColumns(allColumnIds, false);
  // };

  onColumnResized = (params) => params;

  autoSizeAll = (skip) => {
    const allColumnIds = [];

    this.gridColumnApi.getColumns().forEach((column) => {
      allColumnIds.push(column.getId());
    });

    this.gridColumnApi.autoSizeColumns(allColumnIds, skip);
  };

  onGridReady = (params) => {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;

    params.api.setRowData(this.props.data);
    this.gridApi.refreshHeader(this.props.header);
    this.gridApi.refreshCells(this.props.data);
    this.autoSizeAll(false);
  };

  render() {
    const { header, data } = this.props;

    const gridOptions = {
      // defaultColDef: {
      //   resizable: true,
      // },
      defaultColDef: {
        flex: 1,
      },
      suppressRowTransform: true,
      frameworkComponents: {
        iconCellRenderer: IconCellRender,
        selectCellRenderer: SelectCellRenderer,
        //headerCellRender: HeaderCellRender,
        activeCellRenderer: ActiveCellRenderer,
        actionCellRenderer: ActionCellRender,
        anchorCellRenderer: AnchorCellRender,
      },
      suppressRowClickSelection: true,
      rowSelection: "multiple",

      onModelUpdated: function () {
        var rowCount = gridOptions.api.getDisplayedRowCount();

        if (rowCount === 0) {
          var noRowsDiv = document.createElement("div");

          noRowsDiv.innerHTML = "No data to display";
          gridOptions.api.showNoRowsOverlay();
          gridOptions.api.getOverlayDiv().appendChild(noRowsDiv);
        } else {
          gridOptions.api.hideOverlay();
        }
      },
    };
    const rowStyle = { background: 'white' };

    // set background colour on even rows again, this looks bad, should be using CSS classes
    const getRowStyle = params => {
      console.log(
      );
        if (params.data.runningStatus === "Active - Idle") {
            return { color: 'green' };
        }
        else if(params.data.runningStatus === "Inactive - Error"){
          return { color: 'red' };
        }
        else if(params.data.runningStatus === "Inactive - Idle"){
          return { color: '#FFC000' };
        }
    };
    const rowClassRules = {
      // apply green to 2008
      'rag-green-outer': (params) => { return params.data.runningStatus === "Active - Idle"; },
  
   
  
      // apply red to 2000
      'rag-red-outer': (params) => { return params.data.runningStatus === "Inactive - Error"; }
  };
  
    const rowHeight = 42;
    return (
      <div
        className="ag-theme-alpine p-0"
        //style={{ height: this.props.height ? this.props.height : "441px" }}
        style={{
          height: this.props.height ? this.props.height : "calc(100vh - 230px)",
        }}
      >
        <AgGridReact
          rowData={data}
          // rowClassRules={rowClassRules}
          rowStyle={rowStyle}
          getRowStyle={getRowStyle}
          columnDefs={header}
          suppressRowClickSelection={true}
          suppressMenuHide={true}
          suppressDragLeaveHidesColumns={true}
          context={{ componentParent: this }}
          gridOptions={gridOptions}
          //isRowSelectable={this.isRowSelectable}
          onSelectionChanged={this.onSelectionChanged}
          components={this.state.components}
          paginationPageSize={this.props.pageSize}
          onGridReady={
            this.props.onGridReady ? this.props.onGridReady : this.onGridReady
          }
          onColumnResized={this.onColumnResized.bind(this)}
          overlayNoRowsTemplate={"No data to display"}
        />
      </div>
    );
  }
}

agGrid.propTypes = {};
