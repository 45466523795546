// Default React, React Components, Router and Services Components
import React, { useEffect, useRef, useState } from "react";
import { Box, Grid, Paper, Tooltip, Typography } from "@mui/material";

// Dummy Data
import { headers, headers2, rowData } from "./Data";

// Importing Custom Components
// import AdvanceFilters from './advanceFilters';
import BaseLayout from "components/commonComponents/baseLayout";
import Gridnav from "components/commonComponents/gridnav/gridnav";
import MaterialUIModal from "components/commonComponents/modal/modal";
import MaterialUIButton from "components/commonComponents/button/button";
import AgGrid from "components/commonComponents/grid/agGrid";
import UsePagination from "components/commonComponents/pagination/pagination";

// Importing useStyles
import { styled } from "@mui/material/styles";
import useStyles from "./slaHolidays.styles";

// Import Material Icons
import RestartAltOutlinedIcon from "@mui/icons-material/RestartAltOutlined";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import AddBoxOutlinedIcon from "@mui/icons-material/AddBoxOutlined";

import {
  addUsers,
  deleteUsers,
  ExportUsers,
  getUsers,
  updateUsers,
  viewUser,
} from "redux/usermanagement/action";
import {
  fetchRole,
  fetchRoles,
  fetchusers,
  fetchUsers,
  fetchUsersid,
} from "redux/app/actions";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import AlertDialog from "components/commonComponents/alertDialog";
import CloseIcon from "@mui/icons-material/Close";
import DoneIcon from "@mui/icons-material/Done";
import { writeFile } from "xlsx";
import { utils } from "xlsx/xlsx";
import moment from "moment";
import Spinner from "components/commonComponents/spinner/spinner";
import { viewRoles } from "redux/rolemanagement/action";
import { useLocation } from "react-router-dom";
import AddSla from "./addSla";
import AddHoliday from "./addHoliday";
import {
  addHoliday,
  addSla,
  deleteHoliday,
  deleteSla,
  getAllHoliday,
  getAllSla,
  getHoliday,
  getSla,
  updateHoliday,
  updateSla,
} from "redux/slaandholidays/actions";
function SLAHolidays() {
  const dispatch = useDispatch();
  const gridApi = useRef(null);
  const select = useSelector((state) => state.userManagement);
  const id = useSelector((state) => state.project.sla);
  const [show, setShow] = useState(false);
  const [selectExport, setselectExport] = useState([]);
  const [rows, setRows] = useState();
  const [sla, setSla] = useState([]);
  const [holiday, setholiday] = useState([]);

  const [search, setSearch] = useState("");
  const [searchh, setSearchh] = useState("");
  const [advFilterOpen, setAdvFilterOpen] = useState(false);
  const classes = useStyles();
  const [addslaOpen, setAddslaOpen] = useState(false);
  const [addholidayOpen, setAddholidayOpen] = useState(false);
  const [action, setAction] = useState("Add");
  const [actionS, setActionS] = useState("Add");

  const [saveDisabled, setsaveDisabled] = useState(false);
  const [saveDisabledh, setsaveDisabledh] = useState(false);
  const [editHoliday, setEditHoliday] = useState({});
  const [editSla, setEditSla] = useState({});

  const [limit, setLimit] = useState(25);
  const [page, setPage] = useState(1);
  const [pageCount, setpageCount] = useState(0);
  const [total, settotal] = useState(0);
  const [totalCount, settotalCount] = useState(0);

  const [totalRecords, settotalRecords] = useState(0);
  const [limith, setLimith] = useState(25);
  const [pageh, setPageh] = useState(1);
  const [pageCounth, setpageCounth] = useState(0);
  const [totalh, settotalh] = useState(0);
  const [totalCounth, settotalCounth] = useState(0);
  const [totalRecordsh, settotalRecordsh] = useState(0);
  const [Deletedata, setDeletedata] = useState({});
  const [Deletedatah, setDeletedatah] = useState({});
  const [showDelete, setshowDelete] = useState(false);
  const [showDeleteh, setshowDeleteh] = useState(false);

  const [disableedit, setdisableedit] = useState(false);
  const [load, setLoad] = useState(true);
  const [view, SetView] = useState(false);
  const [showEdit, setShowEdit] = useState(false);
  const filterBody = {};
  const selector = JSON.parse(localStorage.getItem("roleData"));

  const [add, setAdd] = useState([]);
  const location = useLocation();

  useEffect(() => {
    if (search.length > 0) {
      const timerId = setTimeout(() => {
        performSearch(search);
      }, 2000);
      return () => clearTimeout(timerId);
    } else {
      setPage(1);

      getAllSlaManagement(1, limit, search); // Update the results state with the fetched data
    }
  }, [search]);

  const performSearch = (value) => {
    setPage(1);
    let encodedValue = encodeURIComponent(value);
    getAllSlaManagement(1, limit, encodedValue); // Update the results state with the fetched data
    // setResults(responseData);
  };
  useEffect(() => {
    if (searchh.length > 0) {
      const timerId = setTimeout(() => {
        performSearchh(searchh);
      }, 2000);
      return () => clearTimeout(timerId);
    } else {
      setPageh(1);

      getAllHolidayManagement(1, limith, searchh); // Update the results state with the fetched data
    }
  }, [searchh]);

  const performSearchh = (value) => {
    setPageh(1);
    let encodedValue = encodeURIComponent(value);
    getAllHolidayManagement(1, limith, encodedValue); // Update the results state with the fetched data
    // setResults(responseData);
  };

  const handleInputChange = (e) => {
    const value = e.target.value;
    setSearch(value);
  };
  const handleInputChangeh = (e) => {
    const value = e.target.value;
    setSearchh(value);
  };
  useEffect(() => {
    getAllSlaManagement(page, limit, "");
    getAllHolidayManagement(page, limit, "");
    setAdd(
      selector &&
        selector.roleConfigurations.filter((e) =>
          e.menuName === "Manage Issue/Request"
            ? location.pathname === "/manage-issue-/-request" &&
              e.isAdd === true
            : e.menuName.replace("&", "and").toLowerCase() ===
                location.pathname
                  .replace("/", "")
                  .replace("-", " ")
                  .replace("-", " ")
                  .replace("/", "#")
                  .split("#")[0] && e.isAdd === true
        )
    );
  }, [id]);

  /******************************************************************************
  Function: getAllSlaManagement
  Argument: page, limit, search
  Return: 
  Usage:
  1.To get all users in grid
  *******************************************************************************/

  const getAllSlaManagement = (page, limit, search) => {
    dispatch(getAllSla(page, limit, search, id)).then((res) => {
      if (res) {
        setLoad(false);
        if (res.data.statusCode === 200) {
          if (res.data.result === "No records found") {
            settotalCount(0);
            settotalRecords(0);
            setSla([]);
          } else {
            setSla(res.data.result);
          }
          setLoad(false);

          settotal(res.data.result.length);
          setpageCount(Math.ceil(total / limit));
          settotalCount(res.data.total);
          settotalRecords(res.data.totalRecords);
        }
      }
    });
  };
  const getAllHolidayManagement = (page, limit, search) => {
    dispatch(getAllHoliday(page, limit, search, id)).then((res) => {
      if (res) {
        setLoad(false);
        if (res.data.statusCode === 200) {
          if (res.data.result === "No records found") {
            settotalCounth(0);
            settotalRecordsh(0);
            setholiday([]);
          } else {
            setholiday(res.data.result);
          }
          setLoad(false);

          settotalh(res.data.result.length);
          setpageCounth(Math.ceil(total / limit));
          settotalCounth(res.data.total);
          settotalRecordsh(res.data.totalRecords);
        }
      }
    });
  };
  /******************************************************************************
  Function: handlePageClick
  Argument: event, data
  Return: 
  Usage:
  1.To handle page navigation
  *******************************************************************************/

  const handlePageClick = async (event, data) => {
    setLoad(true);
    setPage(data);
    await getAllSlaManagement(data, limit, search);
    gridApi.current.redrawRows(select.usersdata);
  };

  const handlePageClickh = async (event, data) => {
    setLoad(true);
    setPageh(data);
    await getAllHolidayManagement(data, limith, searchh);
  };

  /******************************************************************************
  Function: handleSelect
  Argument: row
  Return: 
  Usage:
  1.To selete data to export
  *******************************************************************************/

  const handleSelect = async (row) => {
    setselectExport(row.map((e) => e.userId));
    console.log(row.length);
    setRows(row.length === 10 || row.length >= 10);
    if (row.length === 0) {
      setShow(false);
    } else {
      setShow(true);
    }
  };

  const exportdata = selectExport.toString();
  const exportIds = exportdata.split(",");
  /******************************************************************************
  Function: handleExport
  Argument: body
  Return: 
  Usage:
  1.To export data in grid
  *******************************************************************************/

  /******************************************************************************
  Function: editBody
  Argument: data
  Return:
  Usage:
  1.To set edit data 
  *******************************************************************************/

  const editBody = (data) => {
    setActionS("Edit");
    dispatch(getSla(data.id, id)).then((res) => {
      if (res.status === 200) {
        setAddslaOpen(true);
        setEditSla(res.data);
      }

      console.log(res);
    });
  };
  const editBodyH = (data) => {
    setAction("Edit");
    dispatch(getHoliday(data.id, id)).then((res) => {
      if (res.status === 200) {
        setAddholidayOpen(true);
        setEditHoliday(res.data);
      }

      console.log(res);
    });
    // dispatch(updateHoliday(data.id,id)).then((res)=>{
    //   console.log(res);
    // })
    // dispatch(fetchUsersid(data));

    // dispatch(viewUser(data.userId)).then((res) => {});
  };
  /******************************************************************************
  Function: viewBody
  Argument: data
  Return:
  Usage:
  1.To set view data 
  *******************************************************************************/

  const viewBody = (data) => {
    dispatch(fetchUsersid(data));
    SetView(true);
    dispatch(viewUser(data.userId)).then((res) => {});
  };

  /******************************************************************************
  Function: EditUsersdata
  Argument: data, userId, roldId
  Return:
  Usage:
  1.To edit already exisitng role 
  *******************************************************************************/

  /******************************************************************************
  Function: deleteBody
  Argument: event
  Return:
  Usage:
  1.To set delete data 
  *******************************************************************************/

  const deleteBody = (event) => {
    setDeletedata(event.id);
    //setRolename(event.);
  };
  const deleteBodyH = (event) => {
    setDeletedatah(event.id);
    //setRolename(event.);
  };
  /******************************************************************************
  Function: handleDelete
  Argument: userId
  Return:
  Usage:
  1.To delete already exisitng role 
  *******************************************************************************/

  const handleDelete = (userId) => {
    setLoad(true);

    dispatch(deleteSla(userId, id)).then(async (res) => {
      if (res.data.statusCode === 200) {
        toast.success("Sla deleted successfully");
        await getAllSlaManagement(page, limit, search);
      } else {
        toast.error("Failed to delete");
      }
      await gridApi.current.redrawRows(select.usersdata);
    });
  };
  const handleDeleteH = (userId) => {
    setLoad(true);

    dispatch(deleteHoliday(userId, id)).then(async (res) => {
      if (res.data.statusCode === 200) {
        toast.success("Holiday deleted successfully");
        await getAllHolidayManagement(pageh, limith, searchh);
      } else {
        toast.error("Failed to delete");
      }
    });
  };
  /******************************************************************************
  Function: handleGridAction
  Argument:action, selectedRow
  Return:
  Usage:
  1.To handle grid action
  *******************************************************************************/

  const handleGridAction = (action, selectedRow) => {
    switch (action) {
      case "Delete":
        handleDelete(selectedRow);

        break;
      case "DeleteH":
        handleDeleteH(selectedRow);
        break;
      default:
        break;
    }
  };
  const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
    ...theme.typography.body2,
    padding: theme.spacing(2),
    textAlign: "left",
    color: theme.palette.text.secondary,
    boxShadow: "0px 0px 4px 0px rgb(195 0 5 / 10%)",
    position: "relative",
    borderRadius: "14px",
  }));
  /******************************************************************************
  Function: addNewUser
  Argument:data
  Return:
  Usage:
  1.To handle grid action
  *******************************************************************************/

  const addNewSla = (data) => {
    if (actionS === "Edit") {
      setLoad(true);
      dispatch(updateSla(data, id)).then(async (res) => {
        if (res.data.statusCode === 200) {
          setPage(1);
          await getAllSlaManagement(1, limit, search);
          toast.success("SLA saved successfully");
          setAddslaOpen(false);
          setsaveDisabled(false);
          setLoad(false);
        } else if (res.data.statusCode === 400) {
          setAddslaOpen(false);
          setPage(1);
          toast.error(res.data.responseException.exceptionMessage);

          setsaveDisabled(false);
          setLoad(false);
        } else {
          setPage(1);
          setAddslaOpen(false);
          setPage(1);
          toast.error("SLA save failed");
          setsaveDisabled(false);
          setLoad(false);
        }
      });
    } else {
      setLoad(true);
      dispatch(addSla(data, id)).then(async (res) => {
        if (res.data.statusCode === 200) {
          setPage(1);
          await getAllSlaManagement(1, limit, search);
          toast.success("SLA saved successfully");
          setAddslaOpen(false);
          setsaveDisabled(false);
          setLoad(false);
        } else if (res.data.statusCode === 400) {
          setAddslaOpen(false);
          setPage(1);
          toast.error(res.data.responseException.exceptionMessage);

          setsaveDisabled(false);
          setLoad(false);
        } else {
          setPage(1);
          setAddslaOpen(false);
          setPage(1);
          toast.error("SLA save failed");
          setsaveDisabled(false);
          setLoad(false);
        }
      });
    }
  };
  const addNewHoliday = (data) => {
    if (action === "Edit") {
      setLoad(true);
      dispatch(updateHoliday(data, id)).then(async (res) => {
        if (res.data.statusCode === 200) {
          setPageh(1);
          await getAllHolidayManagement(1, limit, search);
          toast.success("Holiday saved successfully");
          setAddholidayOpen(false);
          setsaveDisabledh(false);
          setLoad(false);
        } else if (res.data.statusCode === 400) {
          setAddholidayOpen(false);
          setPageh(1);
          toast.error(res.data.responseException.exceptionMessage);

          setsaveDisabledh(false);
          setLoad(false);
        } else {
          setPageh(1);
          setAddholidayOpen(false);
          setPageh(1);
          toast.error("Holiday save failed");
          setsaveDisabledh(false);
          setLoad(false);
        }
      });
    } else {
      setLoad(true);
      dispatch(addHoliday(data, id)).then(async (res) => {
        if (res.data.statusCode === 200) {
          setPageh(1);
          await getAllHolidayManagement(1, limit, search);
          toast.success("Holiday saved successfully");
          setAddholidayOpen(false);
          setsaveDisabledh(false);
          setLoad(false);
        } else if (res.data.statusCode === 400) {
          setAddholidayOpen(false);
          setPageh(1);
          toast.error(res.data.responseException.exceptionMessage);

          setsaveDisabledh(false);
          setLoad(false);
        } else {
          setPageh(1);
          setAddholidayOpen(false);
          setPageh(1);
          toast.error("Holiday save failed");
          setsaveDisabledh(false);
          setLoad(false);
        }
      });
    }
  };
  const [gridKey, setGridKey] = useState(0);
  const resetAll = async () => {
    // increment the grid key to force a re-render
    setGridKey(gridKey + 1);
    if (search !== "") {
      setPage(1);
      setSearch("");
      await getAllSlaManagement(1, limit, "");
      gridApi.current.api.redrawRows(select.manageIssuedata);
    }

    // increment the grid key to force a re-render
    setGridKey(gridKey + 1);
  };
  const [gridKeyh, setGridKeyh] = useState(0);
  const resetAllH = async () => {
    // increment the grid key to force a re-render
    setGridKey(gridKey + 1);
    if (searchh !== "") {
      setPageh(1);
      setSearchh("");
      await getAllHolidayManagement(1, limith, "");
    }

    // increment the grid key to force a re-render
    setGridKeyh(gridKeyh + 1);
  };
  return (
    <div>
      {/* Manage Issues Page Start Here */}
      <Box>
        <Spinner open={load} />
        {/* BaseLayout Start Here */}
        <BaseLayout>
          <Grid
            container
            spacing={2}
            className={classes.pageActionArea}
            alignItems="flex-start"
            justifyContent="flex-start"
            sx={{ p: 0 }}
          >
            {/* Service Level Agreement Session */}
            <Grid item xs={12} md={6}>
              {/* Action Area - Search by Keyword, Total Record Count, Add Issue, Adv. Filter and Export */}
              <Grid
                container
                spacing={2}
                className={classes.pageActionArea}
                alignItems="center"
                justifyContent="flex-start"
              >
                <Grid item xs={12} md={8}>
                  <Typography
                    variant="subtitle2"
                    gutterBottom
                    className={classes.titleLine}
                  >
                    <span className={classes.artLine}>// </span>
                    Service Level Agreement
                    <span className={classes.artLine}> //</span>
                  </Typography>
                </Grid>

                <Grid item xs={12} md={4}>
                  <Grid
                    container
                    justifyContent="flex-end"
                    alignItems="flex-end"
                    spacing={2}
                  >
                    <Grid item xs={"auto"}>
                      <MaterialUIButton
                        variant="outlined"
                        disabled={add.length === 0}
                        onClick={() => {
                          setAddslaOpen(true);
                          setActionS("Add");
                          setEditSla({});
                        }}
                        message="Add SLA"
                        startIcon={<AddBoxOutlinedIcon />}
                      />
                    </Grid>
                  </Grid>
                </Grid>

                <Grid item xs={12} md={8}>
                  <Grid
                    container
                    alignItems="center"
                    justifyContent="left"
                    spacing={2}
                  >
                    <Grid item xs={"auto"}>
                      {/* Search component with search functionality */}
                      <Gridnav onChange={handleInputChange} data={search} />
                    </Grid>
                    <Grid item xs={"auto"}>
                      {/* Display total results count */}
                      <Typography
                        variant="caption"
                        display="block"
                        gutterBottom
                        className={classes.totalResults}
                      >
                        Total Results:{" "}
                        <span className={classes.totalValue}>
                          {totalRecords ?? 0}
                        </span>
                        &nbsp;
                        <span className={classes.recordText}>Records</span>
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12} md={4}>
                  <Grid
                    container
                    spacing={1.75}
                    justifyContent="flex-end"
                    alignItems="flex-end"
                  >
                    <Grid item xs={"auto"}>
                      {/* Button to clear the search */}
                      <MaterialUIButton
                        variant="outlined"
                        message="Clear Filters"
                        startIcon={<RestartAltOutlinedIcon />}
                        onClick={() => resetAll()}
                      />
                    </Grid>

                    {/* Conditional rendering based on "show" state */}
                  </Grid>
                </Grid>

                <Grid item xs={12}>
                  {/* Grid section starts here */}
                  <Grid
                    container
                    spacing={2}
                    className={classes.pageActionArea}
                    alignItems="center"
                    justifyContent="flex-start"
                    sx={{ p: 0 }}
                  >
                    <Grid item xs={12} className={classes.fullHeight}>
                      {/* AgGrid component for displaying tabular data */}
                      <AgGrid
                        data={sla}
                        header={headers}
                        // selectAll={handleSelect}
                        showEdit={() => setShowEdit(true)}
                        showDelete={() => setshowDelete(true)}
                        showView={() => SetView(true)}
                        edit={editBody}
                        delete={deleteBody}
                        view={viewBody}
                        key={gridKey}
                      />
                      {/* Pagination component */}
                      <UsePagination
                        pageCount={pageCount}
                        count={total}
                        t={totalRecords}
                        total={totalCount}
                        currentPage={page}
                        limit={limit}
                        value={limit}
                        handlePageClick={handlePageClick}
                        onChange={(e) => {
                          setLimit(e.target.value);
                          setPage(1);
                          let encodedValue = encodeURIComponent(e.target.value);
                          getAllSlaManagement(1, encodedValue, search);
                        }}
                      />
                    </Grid>
                  </Grid>
                </Grid>

                {/* <Grid item xs={12}>
                  <AddSla />
                </Grid> */}
              </Grid>
            </Grid>
            {/* Holidays Session */}
            <Grid item xs={12} md={6}>
              {/* Action Area - Search by Keyword, Total Record Count, Add Issue, Adv. Filter and Export */}
              <Grid
                container
                spacing={2}
                className={classes.pageActionArea}
                alignItems="center"
                justifyContent="flex-start"
              >
                <Grid item xs={12} md={8}>
                  <Typography
                    variant="subtitle2"
                    gutterBottom
                    className={classes.titleLine}
                  >
                    <span className={classes.artLine}>// </span>
                    Holidays
                    <span className={classes.artLine}> //</span>
                  </Typography>
                </Grid>

                <Grid item xs={12} md={4}>
                  <Grid
                    container
                    justifyContent="flex-end"
                    alignItems="flex-end"
                    spacing={2}
                  >
                    <Grid item xs={"auto"}>
                      <MaterialUIButton
                        variant="outlined"
                        disabled={add.length === 0}
                        onClick={() => {
                          setAddholidayOpen(true);

                          setAction("Add");
                          setEditHoliday({});
                        }}
                        message="Add Holiday"
                        startIcon={<AddBoxOutlinedIcon />}
                      />
                    </Grid>
                  </Grid>
                </Grid>

                <Grid item xs={12} md={8}>
                  <Grid
                    container
                    alignItems="center"
                    justifyContent="left"
                    spacing={2}
                  >
                    <Grid item xs={"auto"}>
                      <Gridnav onChange={handleInputChangeh} data={searchh} />
                    </Grid>
                    <Grid item xs={"auto"}>
                      {/* Display total results count */}
                      <Typography
                        variant="caption"
                        display="block"
                        gutterBottom
                        className={classes.totalResults}
                      >
                        Total Results:{" "}
                        <span className={classes.totalValue}>
                          {totalRecordsh ?? 0}
                        </span>
                        &nbsp;
                        <span className={classes.recordText}>Records</span>
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12} md={4}>
                  <Grid
                    container
                    spacing={1.75}
                    justifyContent="flex-end"
                    alignItems="flex-end"
                  >
                    <Grid item xs={"auto"}>
                      {/* Button to clear the search */}
                      <MaterialUIButton
                        variant="outlined"
                        message="Clear Filters"
                        startIcon={<RestartAltOutlinedIcon />}
                        onClick={() => resetAllH()}
                      />
                    </Grid>
                  </Grid>
                </Grid>

                <Grid item xs={12}>
                  {/* Grid section starts here */}
                  <Grid
                    container
                    spacing={2}
                    className={classes.pageActionArea}
                    alignItems="center"
                    justifyContent="flex-start"
                    sx={{ p: 0 }}
                  >
                    <Grid item xs={12} className={classes.fullHeight}>
                      {/* AgGrid component for displaying tabular data */}
                      <AgGrid
                        data={holiday}
                        header={headers2}
                        // selectAll={handleSelect}
                        showEdit={() => setShowEdit(true)}
                        showDelete={() => setshowDeleteh(true)}
                        showView={() => SetView(true)}
                        edit={editBodyH}
                        delete={deleteBodyH}
                        view={viewBody}
                        key={gridKeyh}
                      />
                      {/* Pagination component */}
                      <UsePagination
                        pageCount={pageCounth}
                        count={totalh}
                        t={totalRecordsh}
                        total={totalCounth}
                        currentPage={pageh}
                        limit={limith}
                        value={limith}
                        handlePageClick={handlePageClickh}
                        onChange={(e) => {
                          setLimith(e.target.value);
                          setPageh(1);
                          let encodedValue = encodeURIComponent(e.target.value);
                          getAllHolidayManagement(1, encodedValue, searchh);
                        }}
                      />
                    </Grid>

                    {/* <Grid item xs={12}>
                      <AddHoliday />
                    </Grid> */}
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </BaseLayout>
        <MaterialUIModal
          open={addholidayOpen}
          // onClose={() => setAddIssuseOpen(false)}
          // drawerWidth="role"
          anchor="right"
          body={
            <AddHoliday
              save={saveDisabledh}
              onChangeSave={() => setsaveDisabledh(true)}
              close={() => setAddholidayOpen(false)}
              addNewHoliday={addNewHoliday}
              action={action}
              editHoliday={editHoliday}
            />
          }
        />
        <MaterialUIModal
          open={addslaOpen}
          // onClose={() => setAddIssuseOpen(false)}
          // drawerWidth="role"
          anchor="right"
          body={
            <AddSla
              save={saveDisabled}
              onChangeSave={() => setsaveDisabled(true)}
              close={() => setAddslaOpen(false)}
              addNewSla={addNewSla}
              action={actionS}
              editSla={editSla}
            />
          }
        />
        <AlertDialog
          open={showDelete}
          onClose={() => setshowDelete(false)}
          // open={isDeleted}
          title={"Confirmation"}
          // maxWidth={"sm"}
          description={`Do you want to delete the Sla ? `} /* - ${Deletedata} */
          action={
            <Grid
              container
              alignItems="center"
              justifyContent="right"
              spacing={2}
              className={classes.alertDialogbtn}
            >
              <Grid item xs={"auto"}>
                <MaterialUIButton
                  variant="outlined"
                  onClick={() => {
                    handleGridAction("Delete", Deletedata);
                    setshowDelete(false);
                  }}
                  message="Delete"
                  startIcon={<DoneIcon />}
                />
              </Grid>
              <Grid item xs={"auto"}>
                <MaterialUIButton
                  variant="outlined"
                  onClick={() => {
                    setshowDelete(false);
                  }}
                  message="Cancel"
                  startIcon={<CloseIcon />}
                />
              </Grid>
            </Grid>
          }
        />
        <AlertDialog
          open={showDeleteh}
          onClose={() => setshowDeleteh(false)}
          // open={isDeleted}
          title={"Confirmation"}
          // maxWidth={"sm"}
          description={`Do you want to delete the holiday ? `} /* - ${Deletedata} */
          action={
            <Grid
              container
              alignItems="center"
              justifyContent="right"
              spacing={2}
              className={classes.alertDialogbtn}
            >
              <Grid item xs={"auto"}>
                <MaterialUIButton
                  variant="outlined"
                  onClick={() => {
                    handleGridAction("DeleteH", Deletedatah);
                    setshowDeleteh(false);
                  }}
                  message="Delete"
                  startIcon={<DoneIcon />}
                />
              </Grid>
              <Grid item xs={"auto"}>
                <MaterialUIButton
                  variant="outlined"
                  onClick={() => {
                    setshowDeleteh(false);
                  }}
                  message="Cancel"
                  startIcon={<CloseIcon />}
                />
              </Grid>
            </Grid>
          }
        />
      </Box>
    </div>
  );
}

export default SLAHolidays;
