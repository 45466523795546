import {
  BASE_URL_ISSUE,
  CATEGORY_DROPDOW,
  COMPLEXITY_DROPDOWN,
  COUNTRY_DROPDOW,
  COUNTRY_DROPDOWD,
  DEVELOPER_COMPLEXITY_DROPDOWN,
  DROPDOWN_PROVIDER,
  FIELD_DROPDOW,
  MAKE_DROPDOW,
  MAKE_DROPDOWD,
  MAKE_STRING_DROPDOW,
  MANAGE_ISSUE_DELETE_URL,
  MANAGE_ISSUE_DEV_COMMENTS,
  MANAGE_ISSUE_EXPORT_URL,
  MANAGE_ISSUE_UPDATE_URL,
  MERIT_USERS,
  MODEL_DROPDOW,
  MODEL_DROPDOWD,
  OPEN_DROPDOW,
  PRIORITY_DROPDOW,
  PRIORITY_DROPDOW_L,
  PRIORITY_DROPDOW_NEW,
  PROJECT,
  REPORTEDBY_DROPDOW,
  REPORTEDBY_DROPDOW_NEW,
  REQUEST_TYPE,
  RERUN_DROPDOW,
  RESOLUTION_STATUS,
  ROLE_DROPDOWD,
  STATUS_DROPDOW,
  STATUS_ON_CATEGORY,
  TICKET_ID,
  TICKET_ID_LIST,
  TYPE_DROPDOW,
  USERS,
  WEBSITE,
  WORK_TYPE_DROPDOW,
} from "apiConfig/manageissue";
import {
  deleteData,
  getData,
  postData,
  postFormData,
  putData,
} from "services/basicApiService";
import { MANAGE_ISSUE_EDIT_URL, MANAGE_ISSUE_URL } from "apiConfig/manageissue";
import { checkTokenExpiry } from "utils/helper";
const token = localStorage.getItem("token");
console.log(token);
/******************************************************************************
  Function: getTicketID
  Argument: 
  Return: response
  Usage:
  1.get Ticket Id data
  *******************************************************************************/
export const getTicketID = (header) => {
  console.log(header);
  //checkTokenExpiry()
  return async () => {
    const response = await getData(TICKET_ID, {
      headers: {
        "X-Project-ID": header,
      },
    }).catch((error) => {
      throw error;
    });
    if (response) {
      return response;
    }
  };
};

/******************************************************************************
  Function: countryDropdown
  Argument: 
  Return: response
  Usage:
  1.get Country data
  *******************************************************************************/
export const countryDropdown = (header) => {
  return async () => {
    //checkTokenExpiry()
    const response = await getData(COUNTRY_DROPDOW, {
      headers: {
        "X-Project-ID": header,
      },
    }).catch((error) => {
      throw error;
    });
    if (response) {
      return response;
    }
  };
};
export const websiteDropdown = (header) => {
  return async () => {
    //checkTokenExpiry()
    const response = await getData(WEBSITE, {
      headers: {
        "X-Project-ID": header,
      },
    }).catch((error) => {
      throw error;
    });
    if (response) {
      return response;
    }
  };
};
export const countryDropdownDashboard = (header) => {
  return async () => {
    //checkTokenExpiry()
    const response = await getData(COUNTRY_DROPDOWD, {
      headers: {
        "X-Project-ID": header,
      },
    }).catch((error) => {
      throw error;
    });
    if (response) {
      return response;
    }
  };
};
export const providerDropdown = (header) => {
  return async () => {
    //checkTokenExpiry()
    const response = await getData(DROPDOWN_PROVIDER, {
      headers: {
        "X-Project-ID": header,
      },
    }).catch((error) => {
      throw error;
    });
    if (response) {
      return response;
    }
  };
};
/******************************************************************************
  Function: resolutionDropdown
  Argument: 
  Return: response
  Usage:
  1.get Resolution Status data
  *******************************************************************************/
export const resolutionDropdown = (header) => {
  return async () => {
    //checkTokenExpiry()
    const response = await getData(RESOLUTION_STATUS, {
      headers: {
        "X-Project-ID": header,
      },
    }).catch((error) => {
      throw error;
    });
    if (response) {
      return response;
    }
  };
};
/******************************************************************************
  Function: complexityDropdown
  Argument: 
  Return: response
  Usage:
  1.get Complexity data
  *******************************************************************************/
export const complexityDropdown = (header) => {
  return async () => {
    //checkTokenExpiry()
    const response = await getData(COMPLEXITY_DROPDOWN, {
      headers: {
        "X-Project-ID": header,
      },
    }).catch((error) => {
      throw error;
    });
    if (response) {
      return response;
    }
  };
};
/******************************************************************************
  Function: ticketDropdown
  Argument: 
  Return: response
  Usage:
  1.get Ticket Id List data
  *******************************************************************************/
export const ticketDropdown = (header) => {
  return async () => {
    //checkTokenExpiry()
    const response = await getData(TICKET_ID_LIST, {
      headers: {
        "X-Project-ID": header,
      },
    }).catch((error) => {
      throw error;
    });
    if (response) {
      return response;
    }
  };
};
/******************************************************************************
  Function: userDropdown
  Argument: 
  Return: response
  Usage:
  1.get Users data
  *******************************************************************************/
export const userDropdown = (header) => {
  return async () => {
    //checkTokenExpiry()
    const response = await getData(USERS, {
      headers: {
        "X-Project-ID": header,
      },
    }).catch((error) => {
      throw error;
    });
    if (response) {
      return response;
    }
  };
};
export const meritUsers = (header) => {
  return async () => {
    //checkTokenExpiry()
    const response = await getData(MERIT_USERS, {
      headers: {
        "X-Project-ID": header,
      },
    }).catch((error) => {
      throw error;
    });
    if (response) {
      return response;
    }
  };
};
/******************************************************************************
  Function: makeDropdown
  Argument: 
  Return: response
  Usage:
  1.get Make data
  *******************************************************************************/
export const makeDropdown = (header) => {
  return async () => {
    //checkTokenExpiry()
    const response = await getData(MAKE_DROPDOW, {
      headers: {
        "X-Project-ID": header,
      },
    }).catch((error) => {
      throw error;
    });
    if (response) {
      return response;
    }
  };
};

export const projectDropdown = (header) => {
  return async () => {
    //checkTokenExpiry()
    const response = await getData(PROJECT, {
      "X-Project-ID": header,
    }).catch((error) => {
      throw error;
    });
    if (response) {
      return response;
    }
  };
};
/******************************************************************************
  Function: modelDropdown
  Argument: 
  Return: response
  Usage:
  1.get Model data
  *******************************************************************************/
export const modelDropdown = (data, header) => {
  return async () => {
    //checkTokenExpiry()
    const response = await postData(MODEL_DROPDOW, data, {
      "X-Project-ID": header,
    }).catch((error) => {
      throw error;
    });
    if (response) {
      return response;
    }
  };
};
export const modelDropdownDashboard = (data, header) => {
  return async () => {
    //checkTokenExpiry()
    const response = await postData(MODEL_DROPDOWD, data, {
      "X-Project-ID": header,
    }).catch((error) => {
      throw error;
    });
    if (response) {
      return response;
    }
  };
};
export const Status_on = (data, header) => {
  return async () => {
    //checkTokenExpiry()
    const response = await postData(
      STATUS_ON_CATEGORY + `?issueCategory=${data}`,
      {},
      {
        "X-Project-ID": header,
      }
    ).catch((error) => {
      throw error;
    });
    if (response) {
      return response;
    }
  };
};

export const addComments = (data, header) => {
  return async () => {
    //checkTokenExpiry()
    const response = await postData(MANAGE_ISSUE_DEV_COMMENTS, data, {
      "X-Project-ID": header,
    }).catch((error) => {
      throw error;
    });
    if (response) {
      return response;
    }
  };
};

/******************************************************************************
  Function: categoryDropdown
  Argument: 
  Return: response
  Usage:
  1.get Category data
  *******************************************************************************/
export const categoryDropdown = (header) => {
  return async () => {
    //checkTokenExpiry()
    const response = await getData(CATEGORY_DROPDOW, {
      headers: {
        "X-Project-ID": header,
      },
    }).catch((error) => {
      throw error;
    });
    if (response) {
      return response;
    }
  };
};
/******************************************************************************
  Function: typeDropdown
  Argument: 
  Return: response
  Usage:
  1.get Issue Type data
  *******************************************************************************/
export const typeDropdown = (header) => {
  return async () => {
    //checkTokenExpiry()
    const response = await getData(TYPE_DROPDOW, {
      headers: {
        "X-Project-ID": header,
      },
    }).catch((error) => {
      throw error;
    });
    if (response) {
      return response;
    }
  };
};
/******************************************************************************
  Function: priorityDropdown
  Argument: 
  Return: response
  Usage:
  1.get Priority data
  *******************************************************************************/
export const priorityDropdown = (header) => {
  return async () => {
    //checkTokenExpiry()
    const response = await getData(PRIORITY_DROPDOW, {
      headers: {
        "X-Project-ID": header,
      },
    }).catch((error) => {
      throw error;
    });
    if (response) {
      return response;
    }
  };
};
export const priorityDropdownNew = (data,header) => {
 
  return async () => {
    //checkTokenExpiry()
    const response = await postData(PRIORITY_DROPDOW_NEW,data,  {
        "X-Project-ID": header,
      },
    ).catch((error) => {
      throw error;
    });
    if (response) {
      return response;
    }
  };
};
export const priorityDropdownLease = (data,header) => {
 
  return async () => {
    //checkTokenExpiry()
    const response = await getData(PRIORITY_DROPDOW_L+`?id=${data}`,{
      headers: {
        "X-Project-ID": header,
      },
    }
    ).catch((error) => {
      throw error;
    });
    if (response) {
      return response;
    }
  };
};
/******************************************************************************
  Function: fieldDropdown
  Argument: 
  Return: response
  Usage:
  1.get Issue Filed data
  *******************************************************************************/
export const fieldDropdown = (header) => {
  return async () => {
    //checkTokenExpiry()
    const response = await getData(FIELD_DROPDOW, {
      headers: {
        "X-Project-ID": header,
      },
    }).catch((error) => {
      throw error;
    });
    if (response) {
      return response;
    }
  };
};
/******************************************************************************
  Function: rerunDropdown
  Argument: 
  Return: response
  Usage:
  1.get Rerun data
  *******************************************************************************/
export const rerunDropdown = (header) => {
  return async () => {
    //checkTokenExpiry()
    const response = await getData(RERUN_DROPDOW, {
      headers: {
        "X-Project-ID": header,
      },
    }).catch((error) => {
      throw error;
    });
    if (response) {
      return response;
    }
  };
};
/******************************************************************************
  Function: worktypeDropdown
  Argument: 
  Return: response
  Usage:
  1.get Worktype data
  *******************************************************************************/
export const worktypeDropdown = (header) => {
  return async () => {
    //checkTokenExpiry()
    const response = await getData(WORK_TYPE_DROPDOW, {
      headers: {
        "X-Project-ID": header,
      },
    }).catch((error) => {
      throw error;
    });
    if (response) {
      return response;
    }
  };
};
/******************************************************************************
  Function: statusDropdown
  Argument: 
  Return: response
  Usage:
  1.get Status data
  *******************************************************************************/
export const statusDropdown = (header) => {
  return async () => {
    //checkTokenExpiry()
    const response = await getData(STATUS_DROPDOW, {
      headers: {
        "X-Project-ID": header,
      },
    }).catch((error) => {
      throw error;
    });
    if (response) {
      return response;
    }
  };
};
/******************************************************************************
  Function: devcomplexityDropdown
  Argument: 
  Return: response
  Usage:
  1.get Issue Complexity data
  *******************************************************************************/
export const devcomplexityDropdown = (header) => {
  return async () => {
    //checkTokenExpiry()
    const response = await getData(DEVELOPER_COMPLEXITY_DROPDOWN, {
      headers: {
        "X-Project-ID": header,
      },
    }).catch((error) => {
      throw error;
    });
    if (response) {
      return response;
    }
  };
};
/******************************************************************************
  Function: openstatusDropdown
  Argument: 
  Return: response
  Usage:
  1.get Open Status data
  *******************************************************************************/
export const openstatusDropdown = (header) => {
  return async () => {
    //checkTokenExpiry()
    const response = await getData(OPEN_DROPDOW, {
      headers: {
        "X-Project-ID": header,
      },
    }).catch((error) => {
      throw error;
    });
    if (response) {
      return response;
    }
  };
};
/******************************************************************************
  Function: makeString
  Argument: 
  Return: response
  Usage:
  1.get Make data
  *******************************************************************************/
export const makeString = (data, header) => {
  return async () => {
    //checkTokenExpiry()
    const response = await postData(MAKE_STRING_DROPDOW, data, {
      "X-Project-ID": header,
    }).catch((error) => {
      throw error;
    });
    if (response) {
      return response;
    }
  };
};
export const makeStringDashboard = (data, header) => {
  return async () => {
    //checkTokenExpiry()
    const response = await postData(MAKE_DROPDOWD, data, {
      "X-Project-ID": header,
    }).catch((error) => {
      throw error;
    });
    if (response) {
      return response;
    }
  };
};
/******************************************************************************
  Function: addNewIssue
  Argument: data
  Return: response
  Usage:
  1.Add a New Issue 
  *******************************************************************************/
export const addNewIssue = (data, header) => {
  return async () => {
    //checkTokenExpiry()
    const response = await postFormData(BASE_URL_ISSUE, data, {
      "X-Project-ID": header,
    }).catch((error) => {
      throw error;
    });
    if (response) {
      return response;
    }
  };
};
/******************************************************************************
  Function: viewAticketData
  Argument: Id
  Return: response
  Usage:
  1.Get the data by Id
  *******************************************************************************/
export const viewAticketData = (id, header) => {
  return async () => {
    //checkTokenExpiry()
    const response = await getData(BASE_URL_ISSUE + `/${id}`, {
      headers: {
        "X-Project-ID": header,
      },
    }).catch((error) => {
      throw error;
    });
    if (response) {
      return response;
    }
  };
};
/******************************************************************************
  Function: getManageIssue
  Argument: pagenumber, pagesize, search,data
  Return: response
  Usage:
  1.Get Manage Issues Data
  *******************************************************************************/
export const getManageIssue = (pagenumber, pagesize, search, data, header) => {
  return async () => {
    //checkTokenExpiry()
    const response = await postData(
      MANAGE_ISSUE_URL +
        `?PageNumber=${pagenumber}&PageSize=${pagesize}&Search=${search}`,
      data,
      {
        "X-Project-ID": header,
      }
    ).catch((error) => {
      throw error;
    });
    if (response) {
      return response;
    }
  };
};
/******************************************************************************
  Function: getManageIssueId
  Argument: Id
  Return: response
  Usage:
  1.Get the data by Id
  *******************************************************************************/
export const getManageIssueId = (id, header) => {
  return async (dispatch) => {
    //checkTokenExpiry()
    // const token = localStorage.getItem("token");
    const response = await getData(MANAGE_ISSUE_EDIT_URL + id, {
      headers: {
        "X-Project-ID": header,
      },
    }).catch((error) => {
      throw error;
    });
    if (response) {
      return response;
    }
  };
};
/******************************************************************************
  Function: deleteIssue
  Argument: Id
  Return: response
  Usage:
  1.Used for deleting the issue based on the ticket Id.
  *******************************************************************************/
export const deleteIssue = (id, header) => {
  return async () => {
    //checkTokenExpiry()
    const response = await deleteData(MANAGE_ISSUE_DELETE_URL + `?id=${id}`, {
      headers: {
        "X-Project-ID": header,
      },
    }).catch((error) => {
      throw error;
    });
    if (response) {
      return response;
    }
  };
};
/******************************************************************************
  Function: deleteFile
  Argument: file
  Return: response
  Usage:
  1.Used for deleting the file based on the file name.
  *******************************************************************************/
export const deleteFile = (file, header) => {
  return async () => {
    //checkTokenExpiry()
    const response = await deleteData(
      MANAGE_ISSUE_DELETE_URL + `?file=${file}`,

      {
        headers: {
          "X-Project-ID": header,
        },
      }
    ).catch((error) => {
      throw error;
    });
    if (response) {
      return response;
    }
  };
};
/******************************************************************************
  Function: AllRerun
  Argument: 
  Return: response
  Usage:
  1.Get rerun data
  *******************************************************************************/
export const AllRerun = (header) => {
  return async () => {
    //checkTokenExpiry()
    const response = await getData(RERUN_DROPDOW, {
      headers: {
        "X-Project-ID": header,
      },
    }).catch((error) => {
      throw error;
    });
    if (response) {
      return response;
    }
  };
};
/******************************************************************************
  Function: AllWorktype
  Argument: 
  Return: response
  Usage:
  1.Get Worktype data
  *******************************************************************************/
export const AllWorktype = (header) => {
  return async () => {
    //checkTokenExpiry()
    const response = await getData(WORK_TYPE_DROPDOW, {
      headers: {
        "X-Project-ID": header,
      },
    }).catch((error) => {
      throw error;
    });
    if (response) {
      return response;
    }
  };
};
/******************************************************************************
  Function: AllStatus
  Argument: 
  Return: response
  Usage:
  1.Get Status data
  *******************************************************************************/
export const AllStatus = (header) => {
  return async () => {
    //checkTokenExpiry()
    const response = await getData(STATUS_DROPDOW, {
      headers: {
        "X-Project-ID": header,
      },
    }).catch((error) => {
      throw error;
    });
    if (response) {
      return response;
    }
  };
};
/******************************************************************************
  Function: updateManageissue
  Argument: data
  Return: response
  Usage:
  1.Used to update the manage issue data
  *******************************************************************************/
export const updateManageissue = (data, header) => {
  return async () => {
    //checkTokenExpiry()
    const response = await postFormData(MANAGE_ISSUE_UPDATE_URL, data, {
      "X-Project-ID": header,
    }).catch((error) => {
      throw error;
    });
    if (response) {
      return response;
    }
  };
};
/******************************************************************************
  Function: ExportManageissue
  Argument: pagenumber, pagesize, search, data
  Return: response
  Usage:
  1.Export Manage issues data 
  *******************************************************************************/
export const ExportManageissue = (
  pagenumber,
  pagesize,
  search,
  data,
  header
) => {
  return async (dispatch) => {
    //checkTokenExpiry()

    const response = await postData(
      MANAGE_ISSUE_EXPORT_URL +
        `?PageNumber=${pagenumber}&PageSize=${pagesize}&Search=${search}`,
      data,
      {
        "X-Project-ID": header,
      }
    ).catch((error) => {
      throw error;
    });
    if (response) {
      return response;
    }
  };
};
/******************************************************************************
  Function: reportedByDropdown
  Argument: data
  Return: response
  Usage:
  1.Get Reported by data
  *******************************************************************************/
export const reportedByDropdown = (header) => {
  return async () => {
    //checkTokenExpiry()
    const response = await getData(REPORTEDBY_DROPDOW, {
      headers: {
        "X-Project-ID": header,
      },
    }).catch((error) => {
      throw error;
    });
    if (response) {
      return response;
    }
  };
};
export const rolesDropdown = (header) => {
  return async () => {
    //checkTokenExpiry()
    const response = await getData(ROLE_DROPDOWD, {
      headers: {
        "X-Project-ID": header,
      },
    }).catch((error) => {
      throw error;
    });
    if (response) {
      return response;
    }
  };
};
export const requestTypeDropdown = (header) => {
  return async () => {
    //checkTokenExpiry()
    const response = await getData(REQUEST_TYPE, {
      headers: {
        "X-Project-ID": header,
      },
    }).catch((error) => {
      throw error;
    });
    if (response) {
      return response;
    }
  };
};
/******************************************************************************
  Function: reportedByDropdown
  Argument: data
  Return: response
  Usage:
  1.Get Reported by data
  *******************************************************************************/
  export const reportedByDropdownNew = (header,data) => {
    return async () => {
      //checkTokenExpiry()
      const response = await getData(REPORTEDBY_DROPDOW_NEW+`?isClient=${data}`, {
        headers: {
          "X-Project-ID": header,
        },
      }).catch((error) => {
        throw error;
      });
      if (response) {
        return response;
      }
    };
  };